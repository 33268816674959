header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;

    @include mq(medium-down) {
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }

    @include mq(medium-up) {
        height: 109px;
    }

    @include mq(medium-up) {
        .social {
            background: $lightOrange !important;
            border: 1px $lightOrange solid !important;
            border-radius: 50% !important;
            bottom: 1rem !important;
            height: 3rem !important;
            left: 1rem !important;
            padding: 0 !important;
            position: fixed !important;
            width: 3rem !important;

            svg {
                height: 1.75rem;
                width: 1.75rem;

                path {
                    fill: #fff;
                    // stroke: #fff;
                }
            }
        }

        .nav__list__item--instagram {
            left: 5rem !important;
        }
    }

    .inner-container {
        background: #fff;
        position: relative;
        width: 100%;
        height: 100%;

        &.is-open {
            @include mq(medium-down) {
                height: 100vh;

                .nav {
                    display: block;
                }
            }

            .nav-icon {
                span:nth-child(1) {
                    top: 18px;
                    width: 0%;
                    left: 50%;
                }

                span:nth-child(2) {
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                }

                span:nth-child(3) {
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                }

                span:nth-child(4) {
                    top: 18px;
                    width: 0%;
                    left: 50%;
                }
            }
        }
    }

    .logo {
        width: 100%;
        height: 109px;
        margin: 0 auto;
        max-width: 1200px;
        background-color: $headerBackgroundColor;

        &__wrapper {
            width: 80px;
            height: auto;
            display: block;
            margin: auto;
            padding: 1rem 0;

            svg {
                width: 100%;
            }

            /* stylelint-disable */
            @media all and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                padding: 0;
            }
            /* stylelint-enable */
        }

        .nav-icon {
            display: none;

            @include mq(medium-down) {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                width: 24px;
                height: 18px;
                padding: 2.65rem 20px;
            }

            &__inner {
                width: 24px;
                height: 18px;
                position: relative;
                transform: rotate(0deg);
                transition: 0.5s ease-in-out;
                cursor: pointer;
            }

            span {
                display: block;
                position: absolute;
                height: 3px;
                width: 100%;
                background: #000;
                opacity: 1;
                left: 0;
                transform: rotate(0deg);
                transition: 0.25s ease-in-out;
            }

            span:nth-child(1) {
                top: 0px;
            }

            span:nth-child(2),
            span:nth-child(3) {
                top: 9px;
            }

            span:nth-child(4) {
                top: 18px;
            }
        }

        @include mq(medium-down) {
            border-bottom: 1px solid $lightGrey;
        }
    }

    .nav {
        font-size: 20px;
        width: 100%;
        font-family: 'Grotezk-Bold', sans-serif;
        background-color: $headerBackgroundColor;
        transition: transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);

        @include mq(medium-down) {
            display: none;

            &.active-sub-navigation {
                transform: translateX(-100%);
            }
        }

        @include mq(medium-up) {
            font-size: 16px;
            max-width: 980px;
            height: 107px;
            margin: -107px auto 0;
            overflow: hidden;
        }

        @include mq(large-up) {
            font-size: 18px;
            max-width: 1100px;
        }

        @include mq(xlarge-up) {
            height: 109px;
            margin: -109px auto 0;
            font-size: 20px;
            max-width: 1200px;
        }

        &__primary {
            @include mq(medium-up) {
                float: left;
            }
        }

        &__secondary {
            @include mq(medium-up) {
                float: right;
            }
        }

        &__list {
            @include navigation-list();

            &__item {
                @include navigation-list-item();

                &--separator {
                    display: none;

                    @include mq(medium-up) {
                        display: block;
                        padding: 2.8rem 0;
                    }

                    @include mq(large-up) {
                        padding: 2.7rem 0;
                    }

                    @include mq(xlarge-up) {
                        padding: 2.65rem 0;
                    }

                    span {
                        display: block;
                        width: 1px;
                        height: 1.4rem;
                        background-color: rgba(0, 0, 0, 0.15);
                    }

                    /* stylelint-disable */
                    @media all and (-ms-high-contrast: none),
                        (-ms-high-contrast: active) {
                        display: none !important;
                    }
                    /* stylelint-enable */
                }

                &.social {
                    width: 27px;

                    @include mq(medium-down) {
                        display: none;
                    }

                    svg {
                        width: 100%;
                    }

                    /* stylelint-disable */
                    @media all and (-ms-high-contrast: none),
                        (-ms-high-contrast: active) {
                        display: none;
                    }
                    /* stylelint-enable */
                }

                &--has-sub-navigation {
                    &:before {
                        @include mq(medium-down) {
                            content: '';
                            position: absolute;
                            line-height: 64px;
                            background: url('../img/sub-navigation-icon.svg')
                                no-repeat center center;
                            background-size: 64px 64px;
                            right: 0px;
                            width: 64px;
                            height: 64px;
                        }
                    }
                }

                &:hover {
                    @include mq(medium-up) {
                        .sub-navigation {
                            transform: translateY(1px);

                            .ruler {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    .sub-navigation {
        position: absolute;
        left: 0;
        width: 100%;
        background-color: $headerBackgroundColor;
        z-index: 10;

        @include mq(medium-down) {
            display: none;
            transform: translateX(100%);
            top: 0;
        }

        @include mq(medium-up) {
            height: 109px;
            transform: translateY(-109px);
            z-index: -1;
            transition: transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86)
                0.3s;
        }

        .ruler {
            opacity: 0;
            transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.3s;
        }
    }

    .ruler {
        height: 1px;
        background-color: $lightGrey;
        position: relative;
        z-index: 888;

        @include mq(medium-down) {
            position: absolute;
            top: 109px;
        }

        &__active-state {
            height: 3px;
            margin-top: -1px;
            transition: width 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86),
                opacity 0.6s ease,
                left 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            background: linear-gradient(to right, $orange, $pink);
            position: absolute;
            opacity: 0;
            top: 0;
        }
    }
}
