.breadcrumb {
  padding: 10px 0;
  background-color: #eee;
  color: #777;
  font-size: 16px;
  border-bottom: 1px solid $lightGrey;
  margin-bottom: -$smallSpacing;
  position: relative;
  z-index: 10;

  &__trail {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    white-space: nowrap;
    overflow: scroll;
  }

  a {
    text-decoration: none;
  }
}