@font-face {
  font-family: 'PlantinMTStd-Italic';
  src: url("../fonts/315F4A_0_0.woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'PlantinMTStd-Regular';
  src: url("../fonts/315F4A_1_0.woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Grotezk-Light';
  src: url("../fonts/Grotezk-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Grotezk-Regular';
  src: url("../fonts/Grotezk-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Grotezk-Bold';
  src: url("../fonts/Grotezk-Bold.woff") format("woff"), url("../fonts/Grotezk-Bold.woff2") format("woff2"),;
  font-weight: normal;
  font-style: normal;
}