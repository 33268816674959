.hero-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  background: linear-gradient(to right, $orange, $pink);
  height: 0;
  overflow: hidden;

  @include mq(small-up) {
    padding-bottom: 36.25%;
  }

  &__background-content {
    display: block;
    width: 100%;
    height: auto;
    // opacity: 0;
    transition: opacity .2s ease;

    @include mq(xsmall-down) {
      position: absolute;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }

    @include mq(small-up) {
      position: absolute;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }

    &.has-image-to-load {
      img {
        opacity: 0;
        transition: opacity 1s ease;
      }

      &.images-loaded {
        img {
          opacity: 1;
        }
      }
    }

    img, video {
      width: 100%;
      transform: translateY(0%);

      @include mq(small-up) {
        height: auto;
        transform: translateY(-20%);
      }
    }
  }

  &__text {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 0;
    color: white;
    font-size: 26px;
    bottom: auto;
    width: 100%;
    text-align: center;
    transform: translate(0,-50%) translateY(0);
    line-height: 110%;

    @include mq(small-up) {
      font-size: 32px;
    }

    @include mq(medium-up) {
      font-size: 52px;
    }

    @include mq(large-up) {
      font-size: 62px;
    }

    p {
      margin: 0;
      transition: opacity 1s ease;
    }
  }

  @keyframes colourAnimation {
    0%   { width: 0; left: 0; }
    50%  { width: 100%; }
    100% { opacity: 0; right: 0; }
  }

  &:before {
    content: "";
    background: linear-gradient(to right, $pink, $pink);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    z-index: 10;

    @include mq(small-up) {
      height: 6px;
    }
  }

  &:after {
    content: "";
    background: linear-gradient(to right, $orange, $orange);
    position: absolute;
    bottom: 0;
    height: 2px;
    z-index: 10;
    animation: colourAnimation 4s infinite;

    @include mq(small-up) {
      height: 6px;
    }
  }
}