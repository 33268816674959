.area-schedule {
    & + & {
        margin-top: $spacing;

        @include mq(medium-up) {
            margin-top: $spacing * 2;
        }
    }

    &__header {
        max-width: 800px;
        margin: 0 auto;
        text-align: center;

        @include arrow-styles();

        .arrow-wrapper {
            display: block;
            margin: 0 auto;
        }

        .arrow-icon {
            margin-top: 0;
        }

        p {
            font-size: 22px;
            line-height: 1.2em;
            margin: 0 auto 2rem;

            @include mq(xsmall-up) {
                font-size: 26px;
            }
        }

        h2 {
            @include text-gradient();
            font-size: 22px;
            line-height: 1.2em;
            margin: 0;

            @include mq(xsmall-up) {
                font-size: 26px;
            }
        }

        h2 + p {
            margin-top: 0.5em;
        }
    }

    &__table {
        width: 100%;

        &--narrow {
            max-width: 800px;
            margin: 0 auto;
        }

        &__row {
            align-items: center;
            display: flex;
            width: 100%;
            position: relative;
            border-bottom: 1px solid rgba(0, 0, 0, 0.4);
            font-size: 1rem;
            transition: transform 0.4s ease;

            &.has-occupiers {
                .area-schedule__table__row__toggle {
                    display: block;
                }
            }

            &:last-child {
                border-bottom: 0;
            }

            &.is-open {
                .area-schedule__table__row {
                    &__occupiers {
                        display: block;
                    }

                    &__toggle {
                        svg {
                            transform: rotate(45deg);
                        }
                    }
                }
            }

            &--head {
                span {
                    @include small-heading();
                    text-align: left;
                    font-size: 6px;
                    border: 0 !important;

                    @include mq(xsmall-up) {
                        font-size: 1rem !important;
                    }
                }
            }

            &:after {
                content: '';
                display: block;
                clear: both;
            }

            &__col {
                float: left;
                font-size: 12px;
                width: calc(50% - 20px);
                padding: #{$smallSpacing / 4} 0;

                &.buttons {
                    @media (max-width: 768px) {
                        display: flex;
                        flex-direction: column;

                        button {
                            position: relative !important;
                            width: fit-content;

                            &:last-child {
                                margin-top: 10px;
                            }
                        }
                    }
                }

                &.details {
                    box-sizing: border-box;

                    &.padding--right {
                        padding-right: 8rem;

                        @media (max-width: 500px) {
                            padding-right: 5rem;
                        }
                    }

                    position: relative;
                    .panoplan-button {
                        right: 115px !important;

                        @media(max-width: 768px) {
                            right: unset !important;
                        }
                    }
                    .panoplan-button-2 {
                        right: 235px !important;
                    }
                    .floorplan-button,
                    .floorplan-button-2,
                    .panoplan-button,
                    .panoplan-button-2 {
                        background: transparent;
                        border: 3px solid $purple;
                        border-radius: 0;
                        color: $purple;
                        font-size: 1rem;
                        font-family: 'Grotezk-Regular', helvetica, sans-serif;
                        padding: 0.5rem 1rem;
                        position: absolute;
                        right: 0;
                        top: calc(50% - 1.25rem);

                        &:focus {
                            border: 3px solid $purple;
                        }

                        @media (max-width: 500px) {
                            font-size: 0.75rem;
                            margin-top: 0.4rem;
                            padding: 0.25rem 0.5rem;
                        }
                    }
                }

                &:first-of-type {
                    width: 40px;

                    @media (max-width: 500px) {
                        width: 60px;
                    }
                }

                &:nth-of-type(2) {
                    @media (max-width: 500px) {
                        width: calc(33.33% - 20px);
                    }
                }

                @include mq(xsmall-up) {
                    font-size: 14px;
                    padding: $smallSpacing / 2 0;
                    width: calc(25% - #{$spacing});

                    &:first-of-type {
                        width: 100px;
                    }
                }

                @media(max-width: 768px) {
                    width: calc(35% - #{100px});
                }

                @include mq(small-up) {
                    font-size: 18px;
                }

                @include mq(medium-up) {
                    font-size: 20px;

                    &:first-of-type {
                        width: 140px;
                    }
                }

                @include mq(large-up) {
                    font-size: 24px;
                }

                @include mq(xlarge-up) {
                    font-size: 26px;
                }

                &:last-of-type {
                    width: calc(49.5% - #{140px});

                    @media(max-width: 768px) {
                        width: calc(55% - #{100px});
                        align-items: center;
                        justify-content: center;
                    }
                }

                // &:nth-child(n + 2) {
                //   span:nth-child(2) {
                //     border-top: 1px dashed rgba(0, 0, 0, 0.4);
                //   }
                // }

                span {
                    padding: 4px 0;
                    display: block;
                }
            }

            &__occupiers {
                // display: none;
                font-size: inherit;
            }

            .button {
                position: absolute;
                top: 50%;
                right: 0;
                padding: 5px;
                margin: 0;

                border-width: 2px;

                transform: translateY(-50%);
                font-size: 16px;

                @include mq(medium-up) {
                    padding: 10px;
                    font-size: 12px;
                }

                @include mq(large-up) {
                    font-size: 14px;
                }

                @include mq(xlarge-up) {
                    font-size: 16px;
                }
            }
        }
    }
}
