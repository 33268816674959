.events-filter {
  padding: $spacing 0 $smallSpacing;
  background: linear-gradient(to right, $orange, $pink);

  @include mq(small-up) {
    padding: $largeSpacing 0 $smallSpacing;
  }

  &__heading {
    display: block;
    font-size: 32px;
    font-weight: normal;
    color: #FFF;
    text-align: center;
    margin: 0;

    @include mq(medium-up) {
      font-size: 62px;
    }
  }

  &__description {
    font-size: 22px;
    line-height: 1.2em;
    text-align: center;
    margin: 2rem auto 0;
    max-width: 800px;
    color: #FFF;

    @include mq(xsmall-up) {
      font-size: 26px;
    }

    @include mq(xlarge-up) {
      font-size: 32px;
    }
  }

  .filter {
    width: 100%;
    text-align: center;
    margin: $spacing 0;

    &__button {
      -webkit-appearance: none;
      background: transparent;
      padding: $smallSpacing / 2 $smallSpacing;
      border: 0;
      border: 3px solid #FFF;
      border-radius: 0;
      outline: 0;
      color: #FFF;
      display: block;
      font-size: 20px;
      font-family: "Grotezk-Bold", helvetica, sans-serif;
      margin: #{$smallSpacing / 2} 0;
  
      &:hover {
        background-color: #FFF;
        cursor: pointer;
        
        span {
          @include text-gradient();
        }
      }

      @include mq(small-down) {
        width: 100%;
      }

      @include mq(small-up) {
        margin: 0 #{$smallSpacing / 2};
        display: inline-block;
      }
    }

    &.is-active {
      .filter__wrapper {
        // display: block;
      }
    }

    &.filter-categories {
      .filter__button--categories {
        background-color: #FFF;

        span {
          @include text-gradient();
        }
      }

      .filter__categories {
        display: block;
      }
    }

    &.filter-date {
      .filter__button--date {
        background-color: #FFF;

        span {
          @include text-gradient();
        }
      }

      .filter__date {
        display: block;
      }
    }

    &__wrapper {
      margin: $spacing 0;
      // display: none;
    }

    &__categories {
      list-style: none;
      padding: 0;
      display: none;
      
      .category {
        padding: #{$smallSpacing / 2} 0;
        border-bottom: 1px solid rgba(255,255,255,0.5);
        color: #FFF;
        font-size: 18px;
        line-height: 1.3em;
        max-width: 800px;
        margin: 0 auto;
        position: relative;

        @include mq(xsmall-up) {
          font-size: 20px;
        }

        &:last-child {
          border-bottom: 0;
        }

        &:hover,
        &.is-active {
          background-color: rgba(255,255,255,0.2);
          cursor: pointer;
        }

        &.is-active {
          background-color: #FFF;
          color: #000;

          .cross-icon {
            display: block;
          }
        }

        &.is-disabled {
          pointer-events: none;
          color: rgba(255,255,255,0.2);
        }

        .cross-icon {
          position: absolute;
          transform: rotate(45deg) translateY(-65%);
          top: 50%;
          right: $smallSpacing;
          vertical-align: middle;
          width: 32px;
          height: 32px;
          display: none;

          @include mq(xsmall-up) {
            width: 38px;
            height: 38px;
          }
        }
      }
    }

    &__date {
      display: none;

      .ui-datepicker-inline {
        width: 100%;
        max-width: 600px;
        background-color: transparent;
        border: 0;
        outline: 0;
        margin: 0 auto;

        .ui-datepicker-header {
          // @include small-heading();
          border: 0;
          outline: 0;
          background-color: transparent;
          // text-transform: uppercase;
          font-family: "Grotezk-Bold", helvetica, sans-serif;
          font-size: 18px;
          margin: 0 0 $smallSpacing;
          word-spacing: -1px;
          line-height: 1.2em;
          color: #FFF;
          text-align: center;

          @include mq(small-up) {
            font-size: 32px;
          }

          .ui-datepicker-next,
          .ui-datepicker-prev {
            background-size: 25px 25px;
            top: 10px;
            border: 0;
            
            border-radius: 0;

            &:hover {
              border: 0;
              cursor: pointer;
              background-color: rgba(255,255,255,0.2);
            }
          }

          .ui-datepicker-next {
            background: url("../img/arrow-next.svg") no-repeat center center;
            background-size: 25px 25px;
            right: 0;
          }

          .ui-datepicker-prev {
            background: url("../img/arrow-prev.svg") no-repeat center center;
            background-size: 25px 25px;
            left: 0;
          }
        }

        table {
          color: #FFF;
          font-family: "Grotezk-Bold", helvetica, sans-serif;

          thead {
            th {
              @include small-heading();
              text-transform: uppercase;
              display: table-cell;
              font-weight: normal;
            }
          }

          td {
            a {
              padding: 8px 0;
              text-align: center;
              background-color: transparent;
              border: 0;
              color: #FFF;
              font-size: 20px;
              border: 1px solid transparent;

              &:hover {
                cursor: pointer;
                background-color: rgba(255,255,255,0.2);
              }

              &.ui-state-active {
                color: black;
                background-color: #FFF;
              }
            }

            &.ui-datepicker-today {
              a {
                border: 1px solid rgba(255,255,255,0.5);
              }
            }
          }
        }
      }
    }
  }
}