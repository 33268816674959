.podcast-item {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  margin-bottom: $spacing;

  &--3-col {
    @include mq(xsmall-up) {
      margin: 0 $smallSpacing $spacing 0;
    }

    @include mq(xsmall-and-small) {
      width: calc(50% - 15px);
      
      &:nth-child(even) {
        margin-right: 0;
      }
    }

    @include mq(medium-up) {
      width: calc(33.33% - 20px);
      
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
  
  &:hover {
    img {
      transform: scale(1.04);
    }

    .arrow-icon--circle-2 {
      stroke-dashoffset: 0;
    }

    .arrow-icon--circle-1 {
      stroke-dasharray: 110;
      stroke-dashoffset: 110;
    }
  }

  &__inner {
    position: relative;
  }

  &__episode {
    position: absolute;
    width: 90px;
    height: 90px;
    top: -$smallSpacing;
    left: -10px;
    text-align: center;
    background-color: #FFF;
    text-align: center;
    white-space: nowrap;
    z-index: 10;

    @include mq(medium-down) {
      top: -$smallSpacing;
      left: 0;
      width: 60px;
      height: 60px;
    }

    &:before {
      content: "";
      display: inline-block;
      width: 0;
      height: 100%;
      vertical-align: middle;
    }

    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 32px;
      color: $pink;

      @include mq(medium-down) {
        font-size: 26px;
      }
    }
  }

  &__link {
    display: block;
    cursor: pointer;

    img {
      width: 100%;
      height: auto;
      transition: transform .3s ease;
    }
  }

  &__date {
    @include small-heading();
  }

  &__text-container {
    background: white;
    padding: 2rem $smallSpacing;
    position: relative;
    width: calc(100% - $spacing);
    max-width: 480px;
    margin: -2rem auto 0;

    &:before {
      content: "";
      width: 100%;
      height: 6px;
      background: linear-gradient(to right, $orange, $pink);
      position: absolute;
      top: 0;
      left: 0;
    }

    @include arrow-styles();
  }

  &__text {
    font-size: 22px;
    line-height: 1.2em;
    word-spacing: -1px;
    text-align: center;
    margin: 1rem 0 0;
    height: 170px;
    overflow: hidden;

    @include mq(xsmall-up) {
      font-size: 26px;
    }

    a {
      color: #000;
      text-decoration: none;
      hyphens: none;

      &:hover {
        text-decoration: none;
      }
    }
  }
}