.campus-amenities {
  background-color: $purple;
  margin: $spacing auto;

  &.colour--orange {
    background-color: $orange;
  }

  @include mq(medium-up) {
    margin: $largeSpacing auto;
  }

  &__no--margin--bottom {
    margin: $spacing auto 0 auto !important;
  }

  &__team-block {
    display: flex;
    //align-items: start;
    flex-direction: column;
    @include mq(small-up) {
      flex-direction: row;
    }
    &__image {
      width: 100%;
      display: flex;
      align-self: start;

      @include mq(medium-up) {
        width: 65%;
      }
      @include mq(small-up) {
        width: 60%;
      }
    }
    &__button_wrapper {
      width: 100%;
      @include mq(xsmall-only) {
        width: 50%;
        margin-bottom: 20px;
      }
    }
    &__button {
      padding: 10px;
      border: 3px solid #de2a99;
      color: #de2a99;
      text-decoration: none;
      width: 40%;
      display: flex;
      justify-content: center;
      font-size: 14px;
      margin-top: 10px;
      margin-bottom: 30px;

      @include mq(large-up) {
        width: 50%;
        margin-top: 60px;
        font-size: 16px;
      }

      @include mq(medium-up) {
        width: 40%;
        font-size: 16px;
        padding: 10px;
        margin-top: 30px;
      }
      @include mq(small-up) {
        font-size: 12px;
        padding: 5px;
        margin-bottom: 0px;
      }

      &:hover {
        background-color: #de2a99;
        color: #fff;
      }
    }
    &__text-block {
      background-color: white;
      color: black;
      width: 100%;

      &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 50px 0 50px;
        @include mq(medium-up) {
          padding: 0 50px 0 50px;
        }
        @include mq(small-up) {
          padding: 0 25px 0 25px;
        }
        height: 100%;
      }
      @include mq(small-up) {
        width: 50%;
      }
      @include mq(large-up) {
        width: 45%;
      }

      h3 {
        @include text-gradient();
        margin-bottom: 30px;
        text-align: left;
        width: 100%;
        font-size: 22px;
        margin-bottom: 10px;

        @include mq(small-up) {
          margin-bottom: 10px;
          font-size: 16px;
        }
        @include mq(large-up) {
          font-size: 24px;
        }
        @include mq(medium-up) {
          font-size: 18px;
          margin-bottom: 0px;
        }
      }
      p {
        text-align: left;
        font-size: 16px;
        width: 100%;
        @include mq(large-up) {
          font-size: 20px;
          margin-top: 30px;
        }
        @include mq(medium-up) {
          font-size: 16px;
          margin-top: 30px;
        }
        @include mq(small-up) {
          font-size: 12px;
          margin: 5px 0 5px 0;
          width: 100%;
        }
      }
    }
  }

  &__text {
    padding: $spacing 0;
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    text-align: center;
    width: 100%;

    &__sub-title {
      @include small-heading();
    }

    &__heading {
      font-size: 26px;
      margin: 1em auto;
      word-spacing: -1px;
      line-height: 1.2em;
      max-width: 80%;

      @include mq(small-up) {
        font-size: 32px;
      }

      @include mq(small-only) {
        max-width: 60%;
        margin-left: auto;
        margin-right: auto;
      }

      @include mq(xlarge-up) {
        font-size: 40px;
      }
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    font-size: 0;

    &:after {
      content: "";
      width: 100%;
      display: block;
      clear: both;
    }

    &__item {
      width: 100%;
      display: inline-block;
      margin: 0 0 $smallSpacing 0;
      vertical-align: top;

      @include mq(xsmall-and-small) {
        width: calc(50% - #{$smallSpacing / 2});
        margin: 0 $smallSpacing $spacing 0;

        &:nth-child(even) {
          margin-right: 0;
        }
      }

      @include mq(medium-up) {
        width: calc(33.33% - 20px);
        margin: 0 $smallSpacing $spacing 0;

        &:nth-child(3n) {
          margin-right: 0;
        }
      }

      img {
        width: 100%;
      }

      p {
        padding: 0 20px;
        text-align: center;
        color: #fff;
        font-size: 26px;
      }
    }
  }
}

.event-calendar {
  &__grid {
    display: flex;
    padding-top: 60px;
    padding-bottom: 60px;
    flex-direction: column;
    @include mq(small-up) {
      flex-direction: row;
    }
    &_title {
      color: #fff;
      padding-bottom: 20px;
      margin-top: 0;
    }
    &_text {
      width: 90%;
      color: #fff;
      font-size: 16px;
      @include mq(small-up) {
        width: 90%;
        font-size: 18px;
      }
      @include mq(medium-up) {
        width: 70%;
        font-size: 20px;
      }
    }

    &__column-1 {
      width: 100%;
      @include mq(small-up) {
        width: 40%;
      }
    }
    &__column-2 {
      width: 100%;
      @include mq(small-up) {
        width: 60%;
      }
      &_image {
        height: 600px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }
}
