.tertiary-navigation {
  position: relative;
  padding: 15px 0;
  background-color: $headerBackgroundColor;
  border-bottom: 1px solid $lightGrey;
  z-index: 10;

  @include mq(medium-up) {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1000;
  }

  &__list {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
    font-size: 0;
    text-align: center;
    white-space: nowrap;
    overflow-y: auto;
    list-style: none;
  }

  &__item {
    display: inline-block;
    font-size: 16px;

    @include mq(medium-up) {
      font-size: 12px;
    }

    @include mq(large-up) {
      font-size: 14px;
    }

    @include mq(xlarge-up) {
      font-size: 16px;
    }

    & + & {
      margin-left: 2em;
    }
  }

  &__link {
    text-decoration: none;

    &:hover {
      @include text-gradient();
    }
  }

  &__desktop-text {
    display: none;

    @include mq(small-up) {
      display: inline;
    }
  }

  &__mobile-text {
    @include mq(small-up) {
      display: none;
    }
  }

  .ruler {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 888;

    &__active-state {
      height: 3px;
      margin-top: -2px;
      transition: width .3s cubic-bezier(0.785, 0.135, 0.15, 0.86), opacity .6s ease, left .3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
      background: linear-gradient(to right, $orange, $pink);
      position: absolute;
      opacity: 0;
      top: 0;
    }
  }
}
