.image-slider {
  margin: $spacing auto;
  position: relative;
  @include mq(medium-up) {
    margin: $largeSpacing auto;
  }

  img {
    width: 100%;
    height: auto;
    outline: none;
  }
  figcaption {
    position: absolute;
    color: black;
    bottom: 0;
  }
}

.image-slider-spaceplans {
  margin: $spacing auto;
  position: relative;
  @include mq(medium-up) {
    margin: $largeSpacing auto;
  }

  img {
    width: 100%;
    height: auto;
    outline: none;
  }
  figcaption {
    position: absolute;
    color: black;
    bottom: 0;
  }
}

.image-slider-spaceplans,
.slick-styles {
  .slick-arrow {
    position: absolute;
    top: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    z-index: 1;
    outline: none;
    text-indent: -9999px;
    border: 0;
    cursor: pointer;

    @include mq(small-up) {
      width: 40px;
      height: 40px;
      margin-top: -20px;
    }

    &.slick-prev {
      left: $smallSpacing;
      background: url("../img/arrow-prev-dark.svg") no-repeat center center;
      background-size: cover;

      @include mq(medium-up) {
        left: $spacing;
      }
    }

    &.slick-next {
      right: $smallSpacing;
      background: url("../img/arrow-next-dark.svg") no-repeat center center;
      background-size: cover;

      @include mq(medium-up) {
        right: $spacing;
      }
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 60px;
    width: 100%;
    text-align: center;
    list-style: none;
    padding: 0;
    margin: 0;

    @include mq(xsmall-up) {
      bottom: $smallSpacing;
    }

    @include mq(medium-up) {
      bottom: $spacing;
    }

    li {
      display: inline-block;
      margin: 0 5px;

      &.slick-active {
        button {
          background-color: rgba(0, 0, 0, 0.5);
        }
      }

      button {
        -webkit-appearance: none;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        border: 2px solid rgba(0, 0, 0, 0.5);
        text-indent: -9999px;
        padding: 0;
        background-color: transparent;
        outline: none;

        @include mq(xsmall-up) {
          width: 16px;
          height: 16px;
          border-radius: 100%;
          border: 3px solid rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}

.image-slider,
.slick-styles {
  .slick-arrow {
    position: absolute;
    top: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    z-index: 1;
    outline: none;
    text-indent: -9999px;
    border: 0;
    cursor: pointer;

    @include mq(small-up) {
      width: 40px;
      height: 40px;
      margin-top: -20px;
    }

    &.slick-prev {
      left: $smallSpacing;
      background: url("../img/arrow-prev.svg") no-repeat center center;
      background-size: cover;

      @include mq(medium-up) {
        left: $spacing;
      }
    }

    &.slick-next {
      right: $smallSpacing;
      background: url("../img/arrow-next.svg") no-repeat center center;
      background-size: cover;

      @include mq(medium-up) {
        right: $spacing;
      }
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 60px;
    width: 100%;
    text-align: center;
    list-style: none;
    padding: 0;
    margin: 0;

    @include mq(xsmall-up) {
      bottom: $smallSpacing;
    }

    @include mq(medium-up) {
      bottom: $spacing;
    }

    li {
      display: inline-block;
      margin: 0 5px;

      &.slick-active {
        button {
          background-color: #fff;
        }
      }

      button {
        -webkit-appearance: none;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        border: 2px solid #fff;
        text-indent: -9999px;
        padding: 0;
        background-color: transparent;
        outline: none;

        @include mq(xsmall-up) {
          width: 16px;
          height: 16px;
          border-radius: 100%;
          border: 3px solid #fff;
        }
      }
    }
  }
}

.slick-styles {
  .slick-arrow {
    top: 30%;
  }
}
