.feature {
  text-align: right;

  &--orange {
    background-color: $lightOrange;

    a:hover {
      cursor: pointer;
      color: $lightOrange;
    }
  }

  &--orange-on-white {
    .feature__text {
      color: black;
    }

    .feature__text__heading {
      margin-bottom: 0;
      @include text-gradient();
    }

    a {
      border-color: $lightOrange;
      color: $lightOrange;
    }

    a:hover {
      cursor: pointer;
      color: $lightOrange;
    }
  }

  &--pink {
    background-color: $pink;

    a:hover {
      cursor: pointer;
      color: $pink;
    }
  }

  &--purple {
    background-color: $purple;

    a:hover {
      cursor: pointer;
      color: $purple;
    }
  }

  &--small {
    & > .wrapper {
      position: relative;
      padding-bottom: $spacing;

      @include mq(medium-up) {
        padding-top: $spacing;

        &:after {
          content: "";
          display: block;
          clear: both;
        }

        & > div {
          width: calc(50% - #{$smallSpacing / 2});
          vertical-align: middle;

          &.feature__img {
            float: left;
            margin-right: $smallSpacing;
            transform: translateY(-120px);
          }

          &.feature__text {
            float: right;
          }
        }
      }
    }

    &:hover {
      // .feature__img {
      //   transform: translateY(-#{$largeSpacing}) !important;
      // }
    }
  }

  &--large {
    & > .wrapper {
      @include mq(small-down) {
        padding-bottom: $spacing;
      }

      & > div {
        width: 100%;

        &.feature__text {
          width: 100%;

          .feature__text__heading {
            @include mq(xsmall-up) {
              max-width: 60%;
              margin-left: auto;
              margin-right: auto;
            }
          }
        }

        &.feature__img {
          margin-top: -$spacing;

          @include mq(small-up) {
            transform: translateY(#{$spacing});
          }
        }
      }
    }
  }

  &__img {
    &__inner {
      width: 100%;
      padding-bottom: 66.58%;
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &__text {
    padding: $spacing 0;
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    text-align: center;

    &.no__padding__top {
      padding: 0 0 $spacing 0;
    }

    &__type {
      @include small-heading();
    }

    &__heading {
      font-size: 26px;
      margin: 1em 0;
      word-spacing: -1px;
      line-height: 1.2em;

      @include mq(small-up) {
        font-size: 32px;
      }

      @include mq(small-only) {
        max-width: 60%;
        margin-left: auto;
        margin-right: auto;
      }

      @include mq(xlarge-up) {
        font-size: 40px;
      }
    }

    &__body {
      font-size: 22px;
      line-height: 1.2em;
      text-align: center;
      margin: 2rem auto 2rem;
      max-width: 830px;

      @include mq(xsmall-up) {
        font-size: 26px;
      }

      a:hover {
        color: #fff;
      }
    }

    &__link {
      padding: $smallSpacing / 2 $smallSpacing;
      border: 3px solid #fff;
      color: #fff;
      text-decoration: none;
      display: inline-block;
      font-size: 20px;

      &:hover {
        background-color: #fff;
      }
    }

    &__link-purple {
      padding: $smallSpacing / 2 $smallSpacing;
      border: 3px solid $purple;
      color: $purple;
      text-decoration: none;
      display: inline-block;
      font-size: 20px;

      &:hover {
        background-color: $purple;
        color: white;
      }
    }
  }

  &--extra-padding {
    .feature__text {
      padding: $spacing * 2 0;
    }

    .feature__text__link {
      margin-top: 2rem;
    }
  }

  &--alt-layout {
    .feature__text__type {
      margin: 1em 0 2em 0;
      padding-top: 1em;
    }

    @include mq(small-up) {
      .feature__text {
        padding: 0;
      }

      .feature__text__link {
        margin-top: 2rem;
      }
    }
  }
}
.community-feature {
  background-color: $purple;
  padding: 60px 0 120px 0;
  &__grid {
    display: flex;
    flex-direction: column;
    @include mq(small-up) {
      flex-direction: row;
    }
    &_column-1 {
      width: 100%;
      @include mq(large-up) {
        width: 75%;
      }
      @include mq(small-up) {
        width: 60%;
      }
      &_image {
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 400px;

        @include mq(small-up) {
          height: 100%;
        }
      }
    }
    &_column-2 {
      width: 100%;
      @include mq(large-up) {
        width: 25%;
      }
      @include mq(small-up) {
        width: 40%;
      }
      background-color: white;
      &_wrapper {
        padding: 30px;
      }
      &_gradient-strip {
        background-image: linear-gradient(#ff5100, #e50695);
        width: 100%;
        height: 4px;
      }
      &__button_wrapper {
        width: 100%;
        @include mq(xsmall-only) {
          width: 50%;
          margin-bottom: 20px;
        }
      }
      &__button {
        padding: 10px;
        border: 3px solid #de2a99;
        color: #de2a99;
        text-decoration: none;
        width: 50%;
        display: flex;
        justify-content: center;
        font-size: 14px;
        margin-top: 60px;

        @include mq(large-up) {
          width: 70%;
          font-size: 16px;
        }

        @include mq(medium-up) {
          width: 40%;
          font-size: 14px;
        }

        @include mq(small-only) {
          margin-top: 10px;
        }

        &:hover {
          background-color: #de2a99;
          color: #fff;
        }
      }
      h3 {
        font-size: 22px;
        @include text-gradient();
      }
      &_sub-title {
        padding-top: 30px;
        color: $purple;
        margin-bottom: 5px;
      }
      p {
        font-size: 18px;
      }
    }
  }
}

.community-feature-light {
  background-color: transparent;
  padding: 60px 0 120px 0;
  &__grid {
    display: flex;
    flex-direction: column;
    @include mq(small-up) {
      flex-direction: row;
    }
    &_column-1 {
      width: 100%;
      @include mq(large-up) {
        width: 75%;
      }
      @include mq(small-up) {
        width: 60%;
      }
      &_image {
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 400px;

        @include mq(small-up) {
          height: 100%;
        }
      }
    }
    &_column-2 {
      width: 100%;
      @include mq(large-up) {
        width: 25%;
      }
      @include mq(small-up) {
        width: 40%;
      }
      background-color: white;
      &_wrapper {
        padding: 30px;
      }
      &_gradient-strip {
        background-image: linear-gradient(#ff5100, #e50695);
        width: 100%;
        height: 4px;
      }
      &__button_wrapper {
        width: 100%;
        @include mq(xsmall-only) {
          width: 50%;
          margin-bottom: 20px;
        }
      }
      &__button {
        padding: 10px;
        border: 3px solid white;
        color: white;
        text-decoration: none;
        width: 70%;
        display: flex;
        justify-content: center;
        font-size: 14px;
        margin-top: 50px;

        @include mq(large-up) {
          width: 40%;
          font-size: 16px;
        }

        @include mq(medium-up) {
          width: 50%;
          font-size: 14px;
        }

        @include mq(small-only) {
          width: 70%;
          margin-top: 40px;
        }

        &:hover {
          background-color: white;
          color: black;
        }
      }
      h3 {
        font-size: 22px;
        @include text-gradient();
      }
      &_sub-title {
        padding-top: 30px;
        color: $purple;
        margin-bottom: 5px;
      }
      p {
        font-size: 18px;
      }
    }
  }
}
