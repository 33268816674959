.large-image {
  margin: $spacing auto;

  &.full-width {
    .wrapper {
      max-width: 100% !important;
      padding: 0 !important;
    }
  }

  &.no-border {
    img {
      border: 0 !important;
    }
  }

  @include mq(medium-up) {
    margin: $largeSpacing auto;
  }

  &.no-margin-top {
    margin: 0 auto $spacing;

    @include mq(medium-up) {
      margin: 0 auto $largeSpacing;
    }
  }

  img {
    border: 1px solid $lightGrey;
    width: 100%;
  }
  &__caption-2 {
    margin-top: 20px !important;
    color: black !important;
    display: list-item !important;          /* This has to be "list-item"                                               */
    list-style-type: disc !important;       /* See https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-type     */
    list-style-position: inside !important; 
  }
  &__caption-2,
  &__caption {
    font-size: 14px;
    margin: 0.5em 0;
    color: #aaa;
    text-align: center;

    @include mq(small-up) {
      font-size: 18px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;

    @include mq(small-up) {
      position: absolute;
      top: 0;
      right: $smallSpacing;
      margin-right: -1px;
    }

    @include mq(medium-up) {
      right: $spacing;
    }

    &__left {
      left: $spacing;
      right: unset !important;
    }
  }

  &__button {
    display: block;
    margin: 10px 0 0 0;

    background: $white;

    text-align: center;

    @include mq(small-up) {
      margin: 0 0 10px 0;
    }
  }

  .wrapper {
    @include mq(small-up) {
      position: relative;
    }
  }
}
