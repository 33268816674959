.article {
  margin: $spacing 0;

  @include mq(small-up) {
    margin: $largeSpacing 0;
  }

  &--centered {
    article {
      text-align: center;
    }
  }

  article {
    max-width: 650px;
    margin: 0 auto;
    font-size: 18px;
    line-height: 1.2em;
    letter-spacing: 0.5px;

    @include mq(small-up) {
      font-size: 24px;
    }

    h1, h2, h3, h4, h5 {
      // @include small-heading();
      @include text-gradient();
      font-size: 24px;
      line-height: 1.2em;
      text-align: left;
      margin: 2em 0 0.1em;

      @include mq(small-up) {
        font-size: 32px;
      }
    }

    i {
      font-style: normal;
    }
  }
}