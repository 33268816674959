.video-embed {
  margin: $spacing auto;

  @include mq(medium-up) {
    margin: $largeSpacing auto;
  }
  
  &__wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}