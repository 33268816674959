.contact-page {
  background: linear-gradient(to bottom right, $pink, $orange);

  section {
    background: none !important;
  }
}

.contact-table {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 2rem;

  p:first-of-type {
    margin-top: 0rem !important;
  }

  & > div {
    padding: 1rem;
    margin-bottom: 2rem;
    width: 33.33%;
  }

  @media (max-width: 900px) {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }
}

.contact-table-twin {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 2rem;

  p:first-of-type {
    margin-top: 0rem !important;
  }

  & > div {
    padding: 1rem;
    margin-bottom: 2rem;
    width: 50%;
  }

  @media (max-width: 900px) {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }
}
