.office-grid__list {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .offices-item {
        max-width: 45%;

        @media(max-width: 768px) {
            max-width: 100%;
        }

        &:first-child, &:nth-child(2n+1) {
            margin-right: 50px;

            @media(max-width: 768px) {
                margin-right: 0;
            }
        }

        &:hover {
            // img {
            //     transform: scale(1.04);
            // }
            // #offices-item__inner-img::before {
            //     bottom: -5px;
            // }
        }
    
        a {
            color: #000;
            display: block;
            text-decoration: none;
            hyphens: none;
            margin-bottom: 2rem;
            background-color: #fff;
            max-width: 70%;
            margin: 0 auto;
            top: -100px;
            position: relative;
            padding: 40px 20px 20px 20px;

            &:before {
                content: '';
                width: 100%;
                height: 6px;
                background: linear-gradient(to right, $orange, $pink);
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                transition: bottom 0.3s ease;
            }
    
            &:last-of-type {
                margin-bottom: 0;
            }
    
            &:hover {
                text-decoration: none;
    
                .arrow-icon--circle-2 {
                    stroke-dashoffset: 0;
                }
    
                .arrow-icon--circle-1 {
                    stroke-dasharray: 110;
                    stroke-dashoffset: 110;
                }
            }
        }
        #offices-item__inner-img {
            position: relative;
        }
        h2 {
            margin-top: 0;
        }
    
        &__inner {
            position: relative;
    
            &__link {
                display: block;
    
                &[href] {
                    cursor: pointer;
                }
    
                img {
                    width: 100%;
                    height: auto;
                    transition: transform 0.3s ease;
                    z-index: -1;
                }
            }
    
            &__type {
                @include small-heading();
            }
    
            &__title {
                @include text-gradient();
                text-align: center;
                width: 100%;
                margin: 0 0 0.5rem;
                font-size: 22px;
                line-height: 1.2em;
                word-spacing: -1px;
    
                @include mq(xsmall-up) {
                    font-size: 26px;
                }
            }
    
            &__text-container {
                background: white;
                padding: 2rem $smallSpacing;
                position: relative;
                width: calc(100% - $spacing);
                max-width: 480px;
                margin: auto 0;
    
                // @include mq(medium-up) {
                //   height: 100%;
                // }
    
                & > a {
                    border-top: 1px solid #ccc;
                    padding-top: 1.5rem;
                }
    
                @include arrow-styles();
            }
    
            &__text {
                font-size: 22px;
                line-height: 1.2em;
                word-spacing: -1px;
                text-align: center;
                margin: 1rem 0 0;
                hyphens: none;
    
                &--min-height {
                    min-height: 2.385em;
                }
    
                @include mq(xsmall-up) {
                    font-size: 26px;
                }
            }

            svg {
                height: 50px;
                margin-top: 30px;
            }
        }
    }
}