.content-slider {
  margin: $spacing auto;

  @include mq(medium-up) {
    margin: $largeSpacing auto;
  }

  &__nav {
    width: 100%;
    text-align: center;
    margin: #{$smallSpacing / 2} 0;
    border-bottom: 1px solid $lightGrey;
    position: relative;

    @include mq(small-up) {
      margin: $smallSpacing 0;
    }

    & > ul {
      display: inline-block;
      list-style: none;
      padding: #{$smallSpacing / 2} 0;
      position: relative;
      margin: 0;

      @include mq(small-down) {
        width: 100%;
      }

      @include mq(small-up) {
        padding: $smallSpacing 0;
      }
    }

    &__item {
      display: block;
      margin: 0;
      font-size: 20px;
      padding: 10px 0;
      cursor: pointer;

      @include mq(small-down) {
        border-bottom: 1px solid $grey;
        width: 100%;
        background-color: #FFF;
      }

      @include mq(small-up) {
        display: inline-block;
        margin: 0 #{$smallSpacing / 3} 0 0;
        font-size: 20px;
        margin: 0 $smallSpacing 0 0;
      }

      @include mq(small-up) {
        font-size: 26px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .rule {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: -2px;
    height: 3px;
    width: 100px;
    transition: all .8s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    background: linear-gradient(to right, $orange, $pink);

    @include mq(small-down) {
      display: none;
    }
  }

  &__slide {
    outline: none;
    height: auto;
  }
}