.event-details {
  margin: $spacing 0 0;

  @include mq(small-up) {
    margin: $largeSpacing 0 0;
  }

  & > .wrapper {
    &:after {
      content: "";
      display: block;
      clear: both;
      width: 100%;
    }
  }

  &__col {
    width: 100%;
    float: left;

    &:last-child {
      margin-bottom: $spacing;
    }

    @include mq(small-up) {
      border-top: 1px solid $lightGrey;
      width: calc(50% - #{$smallSpacing / 2});
      margin-bottom: $spacing;

      &:first-child {
        margin-right: $smallSpacing;
      }
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    max-width: 800px;
    margin: 0 auto;
    font-size: 18px;

    @include mq(xsmall-up) {
      font-size: 20px;
    }

    .list-item {
      border-bottom: 1px solid $lightGrey;
      padding: 10px 0;

      @include mq(xsmall-up) {
        padding: #{$smallSpacing / 2} 0;
      }

      span:first-child {
        display: inline-block;
        margin-right: 20px;
        vertical-align: middle;
        width: 100px;
      }

      span:not(:first-child) {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        border: 1px solid $pink;
        color: $pink;
        padding: 5px 10px;
        margin-right: 5px;

        @include mq(xsmall-up) {
          font-size: 18px;
        }

        a {
          text-decoration: none;
        }
      }
    }
  }

  .event-notes {
    background-color: #FFF;
    padding: $smallSpacing;
    margin: $smallSpacing 0;
    float: left;
    width: calc(100% - #{$spacing});
    clear: both;
    font-size: 18px;
    position: relative;

    @include mq(xsmall-up) {
      font-size: 20px;
    }

    @include mq(small-up) {
      margin: 0 0 $spacing;
    }

    &:before {
      content: "";
      width: 100%;
      height: 4px;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(to right, $orange, $pink);
    }

    h1, h2, h3, h4, h5, h6 {
      margin: 0 0 0.5em;
      text-decoration: underline;
      display: block;
    }

    p {
      margin: 0 0 1em;
    }

  }

  .book-now-wrapper {
    float: left;
    width: 100%;
    clear: both;
    text-align: center;
    margin: 0 0 #{-$smallSpacing};
    position: relative;
    z-index: 10;

    a {
      padding: $smallSpacing / 2 $smallSpacing;
      border: 3px solid $purple;
      color: $purple;
      text-decoration: none;
      display: inline-block;
      font-size: 18px;
      background-color: #FFF;

      @include mq(xsmall-up) {
        font-size: 20px;
      }

      &:hover {
        background-color: $purple;
        color: #FFF;
      }
    }
  }
}