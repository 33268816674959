.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background: white;
  padding: 20px;
  padding-bottom: 50px;
  width: 50%;
  max-width: 600px;
  text-align: center;
  border-radius: 8px;
  form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @include mq(small-up) {
      flex-direction: row;
    }
  }
}

.modal-buttons {
  margin: 20px 0 20px 0;
}

.modal-buttons a {
  padding: 10px 20px;
  margin: 10px;
  border: none;
  cursor: pointer;
}

#modal-title {
  color: black;
  font-size: 1.5rem;
}

#modal-summary {
  color: black;
  font-size: 1.2rem;
}

#modal-email {
  padding: 10px;

  width: 100%;
  margin-bottom: 20px;
  @include mq(small-up) {
    width: 50%;
    margin-right: 10px;
    margin-bottom: 0;
  }
}

#confirm-download {
  background-color: #28a745;
  color: #fff;
  text-decoration: none;
  border-radius: 2px;
  border: 0;
  font-size: 0.8rem;
  padding: 12px 25px;
  display: flex;
  cursor: pointer;
  width: 70%;
  @include mq(small-up) {
    width: auto;
  }
}
