.podcast-item__wrapper {
    margin: $spacing 0 -50px 0;

    .podcast-item__image {
        img {
            width: 100%;
        }
    }

    .podcast-item__content {
        background-color: $orange;
        text-align: center;
        padding: 50px 30px;
        max-width: 80%;
        margin: 0 auto;
        transform: translateY(-100px);

        span {
            @include small-heading();
            color: #fff;
        }

        h2 {
            font-size: 26px;
            margin: 1em 0;
            word-spacing: -1px;
            line-height: 1.2em;
            color: #fff;

            @include mq(small-up) {
                font-size: 32px;
            }
    
            @include mq(small-only) {
                max-width: 60%;
                margin-left: auto;
                margin-right: auto;
            }
    
            @include mq(xlarge-up) {
                font-size: 40px;
            }
        }

        a:hover {
            color: $orange;
        }
    }
}