.podcast-player {
  margin: 0 auto $largeSpacing;
  background: linear-gradient(to right, $orange, $pink);
  position: relative;

  @include mq(medium-up) {
    margin: 0 auto $largeSpacing;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: calc(50% - 20px);
      z-index: 1;
      height: 10px;
      background-color: rgb(251, 210, 234);
    }
  }

  & > .wrapper {
    position: relative;
    // padding-bottom: $spacing;

    @include mq(medium-up) {
      // padding-top: $spacing;

      &:after {
        content: "";
        display: block;
        clear: both;
      }

      & > div {
        width: calc(50% - #{$smallSpacing / 2});
        vertical-align: middle;

        &.podcast-player__img {
          float: right;
          transform: translateY(#{$spacing});
        }

        &.podcast-player__text {
          float: left;
          margin-right: $smallSpacing;
        }
      }
    }
  }

  &__img {
    transform: translateY(#{$spacing});

    &__inner {
      width: 100%;
      padding-bottom: 100%;
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
      }
    }
  }

  &__text {
    padding: $spacing 0;
    margin: $spacing $smallSpacing $spacing 0;
    display: inline-block;
    vertical-align: middle;
    color: #FFF;
    text-align: center;

    @include mq(small-down) {
      margin: 0;
    }

    @include mq(medium-down) {
      margin: $spacing 0 0 0;
    }

    &__inner {
      @include mq(medium-down) {
        position: relative;
        padding-bottom: $largeSpacing;
      }
    }

    &__episode {
      position: absolute;
      width: 145px;
      height: 145px;
      top: -$spacing;
      left: $spacing;
      text-align: center;
      background-color: #FFF;
      text-align: center;
      white-space: nowrap;

      @include mq(xsmall-down) {
        top: -#{$spacing + 30px};
      }

      @include mq(medium-down) {
        top: -$spacing;
        left: 0;
        width: 80px;
        height: 80px;
      }

      &:before {
        content: "";
        display: inline-block;
        width: 0;
        height: 100%;
        vertical-align: middle;
      }

      span {
        display: inline-block;
        vertical-align: middle;
        font-size: 55px;
        color: $lightOrange;

        @include mq(medium-down) {
          font-size: 32px;
        }
      }
    }

    &__type {
      @include small-heading();
    }

    &__heading {
      font-size: 26px;
      margin: 1em 0;
      word-spacing: -1px;
      line-height: 1.2em;

      @include mq(small-up) {
        font-size: 32px;
      }

      @include mq(small-only) {
        max-width: 60%;
        margin-left: auto;
        margin-right: auto;
      }

      @include mq(xlarge-up) {
        font-size: 40px;
      }
    }

    &__link {
      padding: $smallSpacing / 2 $smallSpacing;
      border: 3px solid #FFF;
      color: #FFF;
      text-decoration: none;
      display: inline-block;
      font-size: 20px;

      &:hover {
        background-color: #FFF;
        color: $pink;

        svg {
          path {
            fill: $pink;
          }
        }
      }

      svg {
        max-width: 200px;
        width: 100%;
      }
    }

    #volume {
      position: absolute;
      bottom: #{$spacing + 10px};
      left: 0;
      width: calc(50% - 20px);
      font-size: 0;

      @include mq(medium-down) {
        width: 100%;
        bottom: 8px;
      }

      &.vol-20 {
        span:nth-child(-n+2) {
          background-color: rgb(255,255,255);
        }        
      }

      &.vol-40 {
        span:nth-child(-n+3) {
          background-color: rgb(255,255,255);
        }
      }

      &.vol-60 {
        span:nth-child(-n+4) {
          background-color: rgb(255,255,255);
        }
      }

      &.vol-80 {
        span:nth-child(-n+5) {
          background-color: rgb(255,255,255);
        }
      }

      &.vol-100 {
        span:nth-child(-n+6) {
          background-color: rgb(255,255,255);
        }
      }

      .volume-inner {
        text-align: right;
      }

      .volume-icon {
        background: url("../img/podcast-volume.png") no-repeat center center;
        background-size: 41px 41px;
        width: 41px;
        height: 41px;
        display: inline-block;
        vertical-align: middle;
        margin-right: $smallSpacing / 2;
      }

      span {
        display: inline-block;
        vertical-align: middle;
        width: 10px;
        height: 10px;
        margin-right: 1px;
        background-color: rgba(255,255,255,0.2);
        transition: background-color .6s ease;
        cursor: pointer;

        &.is-active {
          background-color: rgba(255,255,255,1);
        }
      }
    }

    &__audio-controls {
      position: absolute;
      left: $spacing;
      bottom: 0;
      width: calc(50% - #{$spacing - 15px});
      z-index: 2;

      @include mq(medium-down) {
        left: 0;
        bottom: -$spacing;
        width: 100%;
      }

      .audiojs {
        width: 100%;
        background: none;
        outline: 0;
        border: 0;
        box-shadow: none;

        .time {
          display: none;
        }

        .play-pause {
          position: absolute;
          bottom: $spacing;
          left: 0;
          border: 0;
          width: 61px;
          height: 61px;
          padding: 0;

          p {
            width: 61px;
            height: 61px;
          }

          .play {
            background: url("../img/podcast-icons.png") no-repeat;
            background-position: 0px 0px;
            background-size: 183px 61px;
          }

          .pause {
            background: url("../img/podcast-icons.png") no-repeat;
            background-position: -61px 0px;
            background-size: 183px 61px;
          }

          .loading {
            background: url("../img/podcast-icons.png") no-repeat;
            background-position: -122px 0px;
            background-size: 183px 61px;
          }
        }

        .scrubber {
          position: absolute;
          left: 0;
          bottom: 0;
          margin: 0;
          width: 100%;
          background: #FFF;
          border-top: 0;
          outline: 0;
          height: 10px;
          border-left: 1px solid $pink;

          .loaded {
            background: rgba(229, 6, 149, 0.2);
          }

          .progress {
            background: $pink;
          }
        }
      }
    }
  }
}