.footer {
  position: relative;

  &__enquiries {
    font-size: 0;
    text-align: center;

    &__heading {
      @include small-heading();
      margin: $spacing 0 0 0;

      &:first-child {
        margin: 0;
      }
    }

    &__enquiry {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      margin: $smallSpacing 0;

      @include mq(medium-up) {
        width: calc(35% - #{$smallSpacing});
        margin: 0 $smallSpacing / 2;
      }

      &__list {
        list-style: none;
        padding: 0;

        &__item {
          margin: $smallSpacing 0 0;

          @include mq(xsmall-up) {
            margin: $smallSpacing 0;
          }

          &__text,
          &__heading {
            font-size: 20px;
            line-height: 1.2em;
            text-align: center;
            margin: 0;

            @include mq(xsmall-up) {
              font-size: 26px;
            }

            a {
              color: #000;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }

          &__heading {
            text-decoration: underline;
            margin: $smallSpacing / 2;
          }
        }
      }
    }
  }

  &__credits {
    font-size: 0;
    margin: 0;

    @include mq(medium-up) {
      margin: $spacing 0 0;
    }

    &__col {
      display: inline-block;
      width: 100%;
      font-size: 20px;
      vertical-align: top;
      text-align: center;
      margin: 0 0 $smallSpacing;

      @include mq(medium-up) {
        width: 50%;
        margin: 0;
        text-align: left;
      }

      &.right {
        @include mq(medium-up) {
          text-align: right;
        }
      }
    }
  }

  &__associates {

    @include mq(medium-up) {
      margin-top: -86px;
    }

    &__inner {
      width: 100%;
      margin: 0 auto;
      text-align: center;

      @include mq(medium-up) {
        width: 50%;
      }

      img {
        display: inline-block;
        vertical-align: middle;
        max-width: 150px;
        max-height: 60px;
        margin: ($smallSpacing / 2) auto;

        &:first-child, &:nth-child(3) {
          height: 32px;
        }

        @include mq(xsmall-up) {
          display: inline-block;
          margin: 0 ($smallSpacing / 2);
        }
      }
    }
  }
}

.sign-off {
  width: 100%;
  margin: 0 0 $spacing;

  &__logo {
    display: block;
    margin: 0 auto;
    width: 93px;
    height: auto;
  }
}
