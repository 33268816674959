.event-item {
  background-color: #FFF;
  margin: $spacing 0;
  width: 100%;
  height: auto;
  // transition: transform .4s cubic-bezier(0.785, 0.135, 0.15, 0.86), opacity .3s ease;
  transform: scale(1);
  pointer-events: all;

  &.is-hidden {
    transform: scale(0);
    pointer-events: none;
    animation: changePositionValue 0s;
    // animation-delay: .4s;
    animation-fill-mode: forwards;
    opacity: 0;
  }

  @keyframes changePositionValue {
    from {
      position: static;
    }
    to {
      position: absolute;
    }
  }
  
  &__inner {
    position: relative;
    padding: 0;

    @include mq(medium-up) {
      padding: $smallSpacing 0;
    }

    & > div {
      width: 100%;
      display: block;
    }

    @include mq(medium-up) {
      &:after {
        content: "";
        display: block;
        clear: both;
      }
  
      & > div {
        width: calc(50% - #{$smallSpacing});
      }
    }
  }

  &__categories {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 10;
    
    span {
      display: inline-block;
      font-size: 18px;
      background-color: #FFF;
      border: 1px solid $pink;
      color: $pink;
      padding: 5px 10px;
      margin-right: 5px;
      vertical-align: middle;
    }
  }

  &__img {
    transform: translateY(0);

    @include mq(medium-up) {
      margin: -$spacing $smallSpacing 0;
      float: left;
    }

    &__inner {
      width: 100%;
      padding-bottom: 66.58%;
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &__text {
    padding: $smallSpacing 0 $spacing;
    display: inline-block;
    color: #000;
    text-align: center;

    @include mq(medium-up) {
      float: right;
      padding: $spacing 0;
    }

    &__inner {
      & > a {
        text-decoration: none;
        color: inherit;
  
        &:hover {
          cursor: pointer;
          
          .arrow-icon--circle-2 {
            stroke-dashoffset: 0;
          }
  
          .arrow-icon--circle-1 {
            stroke-dasharray: 110;
            stroke-dashoffset: 110;
          }
        }
      }
    }

    &__type {
      @include small-heading();
    }

    &__heading {
      font-size: 26px;
      margin: 1em 0;
      word-spacing: -1px;
      line-height: 1.2em;
      padding: 0 $smallSpacing;

      @include mq(small-up) {
        font-size: 32px;
        padding: 0 $spacing;
      }

      @include mq(small-only) {
        max-width: 60%;
        margin-left: auto;
        margin-right: auto;
      }

      @include mq(xlarge-up) {
        font-size: 40px;
      }

      span {
        @include text-gradient();
      }
    }

    &__links {
      position: absolute;
      bottom: -20px;

      @include mq(medium-down) {
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
      }

      @include mq(medium-up) {
        bottom: -$smallSpacing;
        right: $smallSpacing;
      }
    }

    &__link {
      padding: 10px 20px;
      border: 2px solid $purple;
      color: $purple;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 0 10px;
      background-color: #FFF;

      @include mq(medium-up) {
        border: 3px solid $purple;
        padding: $smallSpacing / 2 $smallSpacing;
        font-size: 20px;
      }

      &:hover {
        background-color: $purple;
        color: #FFF;
      }
    }

    @include arrow-styles();
  }
  
}