.office-grid {
  text-align: center;
  margin: $spacing 0;

  @include mq(small-up) {
    margin: $largeSpacing 0;
  }

  &--has-background {
    background-color: $pink;
    margin: $spacing 0 0;

    @include mq(small-up) {
      margin: $largeSpacing 0 0;
    }

    .entries-grid__list {
      transform: translateY(-#{$spacing});
    }
  }

  &--less-top-margin {
    margin-top: $spacing / 2;

    @include mq(small-up) {
      margin-top: $largeSpacing / 2;
    }
  }

  &__list {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
    // font-size: 0;
    text-align: center;
    width: 100%;

    &:after {
      content: "";
      display: block;
      clear: both;
      width: 100%;
    }
  }
}
