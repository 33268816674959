.testimonials {
  margin: $spacing auto;

  @include mq(medium-up) {
    margin: $largeSpacing auto;
  }
}

.testimonial {
  text-align: center;

  &__image-wrapper {
    width: 200px;
    height: 200px;
    border-radius: 100px;
    margin: 1rem auto;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  &__text {
    max-width: 1000px;
    padding: 0 $smallSpacing;
    margin: 0 auto;
    display: block;

    p {
      margin: 0;
      text-align: center;
      font-size: 20px;
      @include text-gradient();

      @include mq(small-up) {
        font-size: 32px;
      }
  
      @include mq(medium-up) {
        font-size: 62px;
      }
    }

    div {
      display: block;
      margin: 2rem 0;

      span {
        @include small-heading();
      }
    }
  }
}