$pink: rgba(229, 6, 149, 0.8);
$orange: rgb(255, 81, 0);

$lightOrange: rgb(255, 116, 51);
$purple: rgb(92, 15, 139);
$lightGrey: #e3e4e5;
$grey: #eee;
$white: #fff;
$offWhite: #f8f8f8;
$siteBackground: $offWhite;
$headerBackgroundColor: $white;


$spacing: 60px;
$largeSpacing: $spacing * 2;
$smallSpacing: $spacing / 2;
