.cards {

  &__list {
    list-style: none;
    padding: 0;

    &:after {
      content: "";
      width: 100%;
      display: block;
      clear: both;
    }

    .card {
      float: left;
      width: 100%;
      margin: 0 $smallSpacing $smallSpacing 0;

      @include mq(xsmall-and-small) {
        width: calc(50% - #{$smallSpacing / 2});
        margin: $smallSpacing $smallSpacing $smallSpacing 0;

        &:nth-child(-n + 2) {
          margin-top: 0;
        }

        &:nth-child(even) {
          margin-right: 0;
        }
      }

      @include mq(medium-up) {
        width: calc(25% - 22.5px);
        margin: $smallSpacing $smallSpacing $smallSpacing 0;

        &:nth-child(-n + 4) {
          margin-top: 0;
        }

        &:nth-child(4n) {
          margin-right: 0;
        }
      }

      .no-touchevents &:hover {
        cursor: pointer;

        .card__columns {
          transform: translateX(-#{$smallSpacing});
        }
      }

      &.is-open {
        .card__columns {
          transform: translateX(-100%) !important;
        }
      }

      &__inner {
        position: relative;
        width: 100%;
        // border-radius: 10px;
        padding-bottom: 120%;
        overflow: hidden;
        text-align: center;
        background-color: white;
      }

      &__columns {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(0);
        transition: transform .3s ease;
      }

      &__column {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        white-space: nowrap;

        &:before {
          content: "";
          width: 0;
          height: 100%;
          display: inline-block;
          vertical-align: middle;
        }

        &:last-child {
          right: -100%;
          background: linear-gradient(to right, $orange, $pink);
        }
      }

      &__icon {
        width: 130px;
        height: 130px;
        margin: -120px auto 0;
        display: inline-block;
        vertical-align: middle;

        img {
          width: 100%;
          height: auto;
          transform: scale(1);
          transition: transform .3s ease;
        }
      }

      &__title {
        position: absolute;
        bottom: $smallSpacing;
        width: 100%;
        white-space: normal;

        h4 {
          @include text-gradient();
          font-size: 32px;
          line-height: 1.2em;
          margin: 0;
        }
      }

      &__summary {
        width: calc(100% - 40px);
        margin: 0 auto 0;
        display: inline-block;
        vertical-align: middle;
        white-space: normal;

        p {
          // @include text-gradient();
          color: white;
          padding: 0 20px;
        }
      }
    }
  }
}