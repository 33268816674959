// .negative-margin {
//   section {
//     div {
//       ul {
//         &:first-child {
//           margin-top: -120px;
//         }
//       }
//     }
//   }
//   @include mq(medium-up) {
//     margin-top: -180px;
//   }
//   @include mq(small-up) {
//     margin-top: -160px;
//   }
// }

.entries-grid {
  text-align: center;
  margin: $spacing 0;

  @include mq(small-up) {
    margin: $largeSpacing 0;
  }

  &--has-background {
    background-color: $pink;
    margin: $spacing 0 0;

    @include mq(small-up) {
      margin: $largeSpacing 0 0;
    }

    .entries-grid__list {
      transform: translateY(-#{$spacing});
    }
  }

  &--less-top-margin {
    margin-top: $spacing / 2;

    @include mq(small-up) {
      margin-top: $largeSpacing / 2;
    }
  }

  &__list {
    padding: 0;
    list-style: none;
    font-size: 0;
    text-align: center;
    width: 100%;

    &:after {
      content: "";
      display: block;
      clear: both;
      width: 100%;
    }
  }
}
