.modal--floorplan {
    background: rgba(0, 0, 0, 0.5);
    height: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    transition: opacity 0.5s, height 0s, width 0s, z-index 0s;
    transition-delay: 0s, 0.5s, 0.5s, 0.5s;
    top: 0;
    width: 0;
    z-index: -1;
    .pano > div {
        z-index: 1000;
    }
    &.active {
        height: 100%;
        opacity: 1;
        transition-delay: 0s, 0s, 0s, 0s;
        width: 100%;
        z-index: 9999;
    }
    .btn--close {
        background: #fff;
        border: 3px solid $purple;
        border-radius: 0;
        color: $purple;
        font-size: 1rem;
        font-family: 'Grotezk-Regular', helvetica, sans-serif;
        padding: 0.5rem 1rem;
        right: 1rem;
        position: absolute;
        top: 1rem;
        z-index: 1000000;
    }

    .container {
        background: #fff;
        height: calc(100% - 4rem);
        left: 2rem;
        position: fixed;
        top: 2rem;
        width: calc(100% - 4rem);

        .floor,
        .pano {
            align-items: center;
            height: calc(100% - 6rem);
            display: flex;
            justify-content: center;
            left: 0;
            opacity: 0;
            object-fit: contain;
            padding: 3rem;
            position: absolute;
            transition: opacity 0.5s;
            transition-delay: 0s;
            top: 0;
            width: calc(100% - 6rem);

            &.active {
                opacity: 1;
            }

            img {
                height: 100%;
                object-fit: contain;
                width: 100%;
            }
        }
        .pano {
            height: 100%;
            padding: 0;
            width: 100%;
        }

        .hawkeye {
            bottom: 1rem;
            height: auto !important;
            max-width: 250px !important;
            position: absolute;
            right: 1rem;
            width: 20%;
            z-index: 2000;
        }
    }
}
