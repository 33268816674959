.plans {
    &__slides {
        .slide {
            width: 100%;
            height: auto;
            outline: 0;
            position: relative;

            &__img {
                width: 100%;
                height: auto;
                @media (min-width: 798px) {
                    height: 100%;
                    margin-left: auto;
                    margin-right: auto;
                    max-height: 70vh;
                    outline: 0;
                    width: auto;
                }
            }
        }
    }

    &__dots {
        font-size: 26px;
        text-align: center;
        margin: $smallSpacing 0;

        @include mq(small-down) {
            font-size: 18px;
        }

        .orange {
            color: $orange;
        }

        &__dot {
            display: inline-block;
            border: 3px solid $orange;
            margin-bottom: 10px;
            border-radius: 0;
            font-size: 1rem;
            font-family: 'Grotezk-Regular', helvetica, sans-serif;
            padding: 0.5rem 1rem;
            transition: all 0.1s;

            &.is-active,
            &:hover {
                cursor: pointer;
                color: white;
                background-color: $orange;
            }
        }
    }
}

.slide__meta {
    border: 1px solid transparent;
    position: absolute;
    width: calc(100% - 4px);
    top: 0;
    right: 0;
    z-index: 10;
    display: none;

    @include mq(small-up) {
        display: block;
    }

    &.is-open {
        border: 1px solid $lightGrey;
        background-color: rgba(255, 255, 255, 0.9);
        height: calc(100% - 2px);
        overflow-y: auto;

        .toggle-meta {
            background-color: transparent;
            border: 1px solid transparent;

            .toggle-icon {
                transform: rotate(0deg) translateX(25%);
            }
        }

        &[data-active-spaceplan='corporate-open-plan'] {
            .spaceplan[data-name='corporate-open-plan'] {
                display: block;
            }
        }

        &[data-active-spaceplan='media-open-plan'] {
            .spaceplan[data-name='media-open-plan'] {
                display: block;
            }
        }

        &[data-active-spaceplan='tenancy-split'] {
            .spaceplan[data-name='tenancy-split'] {
                display: block;
            }
        }

        &[data-active-spaceplan='media-open'] {
            .spaceplan[data-name='media-open'] {
                display: block;
            }
        }

        &[data-active-spaceplan='open'] {
            .spaceplan[data-name='open'] {
                display: block;
            }
        }

        &[data-active-spaceplan='cellular-with-reception'] {
            .spaceplan[data-name='cellular-with-reception'] {
                display: block;
            }
        }
        &[data-active-spaceplan='ground-floor'] {
            .spaceplan[data-name='ground-floor'] {
                display: block;
            }
        }
        &[data-active-spaceplan='level-1'] {
            .spaceplan[data-name='level-1'] {
                display: block;
            }
        }
        &[data-active-spaceplan='level-2'] {
            .spaceplan[data-name='level-2'] {
                display: block;
            }
        }
        &[data-active-spaceplan='level-3'] {
            .spaceplan[data-name='level-3'] {
                display: block;
            }
        }
        &[data-active-spaceplan='ground-west'] {
            .spaceplan[data-name='ground-west'] {
                display: block;
            }
        }
        &[data-active-spaceplan='floor-1-west'] {
            .spaceplan[data-name='floor-1-west'] {
                display: block;
            }
        }
        &[data-active-spaceplan='open-plan-with-no-reception'] {
            .spaceplan[data-name='open-plan-with-no-reception'] {
                display: block;
            }
        }

        &[data-active-spaceplan='open-plan-no-reception-breakout'] {
            .spaceplan[data-name='open-plan-no-reception-breakout'] {
                display: block;
            }
        }

        &[data-active-spaceplan='open-plan-reception-breakout'] {
            .spaceplan[data-name='open-plan-reception-breakout'] {
                display: block;
            }
        }

        &[data-active-spaceplan='single-occupier'] {
            .spaceplan[data-name='single-occupier'] {
                display: block;
            }
        }

        &[data-active-spaceplan='connected-to-level-4'] {
            .spaceplan[data-name='connected-to-level-4'] {
                display: block;
            }
        }

        &[data-active-spaceplan='connected-to-level-3'] {
            .spaceplan[data-name='connected-to-level-3'] {
                display: block;
            }
        }
    }

    .toggle-meta {
        position: absolute;
        top: 0;
        right: 0;
        padding: $smallSpacing;
        font-size: 20px;
        width: 140px;
        background-color: rgba(255, 255, 255, 0.9);
        border: 1px solid $lightGrey;
        cursor: pointer;

        @include mq(xlarge-up) {
            width: 160px;
            font-size: 26px;
        }

        &:after {
            content: '';
            display: block;
            width: 100%;
            clear: both;
        }

        p {
            margin: 0;
            float: left;
        }

        .toggle-icon {
            float: right;
            position: relative;
            width: 20px;
            padding-top: 14px;
            transform: rotate(-45deg);

            @include mq(xlarge-up) {
                padding-top: 16px;
                width: 26px;
            }

            span {
                display: block;
                position: absolute;
                height: 2px;
                width: 20px;
                background-color: #000;

                @include mq(xlarge-up) {
                    height: 3px;
                    width: 26px;
                }

                &:first-child {
                    transform: rotate(45deg);
                }

                &:last-child {
                    transform: rotate(-45deg);
                }
            }
        }
    }

    .spaceplan {
        padding: $smallSpacing;
        font-size: 0;
        display: none;

        h3 {
            @include text-gradient();
            font-weight: normal;
            font-size: 16px;
            text-decoration: underline;
            margin-top: 0;

            @include mq(medium-up) {
                font-size: 20px;
            }

            @include mq(xlarge-up) {
                font-size: 26px;
            }
        }

        &__columns {
            margin: 0 0 $smallSpacing / 2;

            .col {
                width: 33.33%;
                display: inline-block;
                margin: 0;
                vertical-align: top;

                & > h4 {
                    margin-top: 0;
                    font-size: 16px;
                    text-decoration: underline;

                    @include mq(medium-up) {
                        font-size: 20px;
                    }

                    @include mq(xlarge-up) {
                        font-size: 26px;
                    }
                }
            }
        }

        &__block {
            margin: 0 0 1em;
            font-size: 16px;

            @include mq(medium-up) {
                font-size: 20px;
            }

            @include mq(xlarge-up) {
                font-size: 26px;
            }

            h4 {
                margin: 0;
                text-decoration: underline;
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;
            }
        }

        &__key {
            list-style: none;
            padding: 0;

            li {
                font-size: 14px;
                vertical-align: middle;

                @include mq(medium-up) {
                    font-size: 16px;
                }

                @include mq(xlarge-up) {
                    font-size: 20px;
                }
            }

            .tile {
                display: inline-block;
                width: 9px;
                height: 9px;
                border: 1px solid #000;
                vertical-align: middle;
                line-height: 16px;

                @include mq(medium-up) {
                    line-height: 20px;
                }

                @include mq(xlarge-up) {
                    width: 15px;
                    height: 15px;
                    line-height: 26px;
                }

                &--gc1 {
                    background-color: #d9d3e8;
                }

                &--gc2 {
                    background-color: #e7c8de;
                }

                &--gc3 {
                    background-color: #e49450;
                }

                &--gc4 {
                    background-color: #f1c9a5;
                }

                &--gc5 {
                    background-color: #cf88b3;
                }

                &--pink {
                    background-color: #e554ae;
                }

                &--rose-pink {
                    background-color: #f2abd7;
                }

                &--light-pink {
                    background-color: #f9d5eb;
                }

                &--purple {
                    background-color: #a586c4;
                }

                &--violet {
                    background-color: #c3afd7;
                }

                &--lilac {
                    background-color: #e0d7ec;
                }

                &--grey {
                    background-color: #bebfc1;
                }

                &--sky-blue {
                    background-color: #d3f1ff;
                }

                &--blue {
                    background-color: #86d7ff;
                }
            }
        }
    }
}
