.entries-carousel {
  background-color: $orange;
  text-align: center;

  &__text-container {
    padding: $spacing 0;
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    text-align: center;

    .entries-carousel__button {
      padding: $smallSpacing / 2 $smallSpacing;
      border: 3px solid #fff;
      color: #fff;
      text-decoration: none;
      display: inline-block;
      font-size: 20px;
      margin-top: 50px;

      &:hover {
        background-color: #fff;
        color: #000;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 26px;
    margin: 0 0 1em;
    word-spacing: -1px;
    line-height: 1.2em;

    @include mq(xsmall-up) {
      max-width: 60%;
      margin-left: auto;
      margin-right: auto;
    }

    @include mq(small-up) {
      font-size: 32px;
    }

    @include mq(small-only) {
      max-width: 60%;
      margin-left: auto;
      margin-right: auto;
    }

    @include mq(xlarge-up) {
      font-size: 40px;
    }
  }

  p {
    font-size: 22px;
    line-height: 1.2em;
    text-align: center;
    margin: 2rem auto 0;
    max-width: 800px;

    @include mq(xsmall-up) {
      font-size: 26px;
    }
  }

  &__slider {
    margin-top: -$largeSpacing * 2.5;
    transform: translateY(#{$largeSpacing * 2.5});

    &.no-transform {
      margin-top: 0;
      transform: none;
    }

    a {
      text-decoration: none;
      outline: 0;
    }

    .slick-list {
      padding: 0 $smallSpacing !important;

      @include mq(small-up) {
        padding: 0 #{$largeSpacing * 2} !important;
      }

      @include mq(large-up) {
        padding: 0 #{$largeSpacing * 3} !important;
      }
    }

    .slick-current {
      a {
        pointer-events: all;
      }

      .entries-carousel__slider__entry__text {
        opacity: 1;
      }
    }

    &__entry {
      outline: 0;
      height: auto;
      cursor: pointer;

      a {
        display: block;
        pointer-events: none;
      }

      &:hover {
        .arrow-icon {
          transform: translate3d(5px, 0, 0);
        }

        .arrow-icon--circle {
          stroke-dashoffset: 0;
        }
      }

      &__inner {
        position: relative;
        margin: 0 $smallSpacing;

        @include mq(xsmall-down) {
          margin: 0 10px;
        }
      }

      img {
        width: 100%;
        outline: 0;
      }

      &__text {
        width: 100%;
        text-align: center;
        display: block;
        margin: $smallSpacing 0;
        opacity: 0;
        transition: opacity 0.3s ease;
        outline: 0;

        span {
          @include small-heading();
          color: #fff;
        }

        h2 {
          font-size: 20px;
          margin: 0.5em 0;
          word-spacing: -1px;
          line-height: 1.2em;
          font-weight: 400;
          text-decoration: underline;
          color: #fff;

          @include mq(xsmall-up) {
            margin-left: auto;
            margin-right: auto;
          }

          @include mq(small-up) {
            font-size: 24px;
          }

          @include mq(small-only) {
            margin-left: auto;
            margin-right: auto;
          }

          @include mq(xlarge-up) {
            font-size: 30px;
          }
        }

        @include arrow-styles();
      }
    }
  }
}
