.download {
  display: inline-block;
  width: calc(50% - #{$smallSpacing / 2});
  margin: 0 #{$smallSpacing / 2} #{$smallSpacing / 2} 0;
  background-color: #FFF;
  position: relative;
  vertical-align: top;

  @include mq(small-down) {
    &:nth-child(even) {
      margin-right: 0;
    }
  }

  @include mq(small-up) {
    width: calc(33.33% - #{$smallSpacing});
    margin: 0 $smallSpacing $spacing 0;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  a {
    display: block;
    cursor: pointer;
    text-decoration: none;
  }

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 30px 30px 0;
    border-color: transparent #f8f8f8 transparent transparent;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 20;

    @include mq(xsmall-up) {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 60px 60px 0;
      border-color: transparent #f8f8f8 transparent transparent;
      position: absolute;
      right: 0px;
      top: 0px;
      z-index: 20;
    }
  }

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 0 0 30px;
    border-color: transparent transparent transparent #eee;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 30;

    @include mq(xsmall-up) {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 60px 0 0 60px;
      border-color: transparent transparent transparent #eee;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 30;
    }
  }

  &__inner {
    position: relative;
    padding-bottom: 138%;
  }

  &__text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;

    &:before {
      content: "";
      display: inline-block;
      height: 100%;
      width: 0;
      vertical-align: middle;
    }

    p {
      display: inline-block;
      vertical-algn: middle;
      color: $purple;
      width: 100%;
      font-size: 18px;
      line-height: 1.2em;
      word-spacing: -1px;

      @include mq(xsmall-up) {
        font-size: 26px;
      }
    }
  }

  &__rule {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(to right, $orange, $pink);
  }
}