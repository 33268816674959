.office-item {
    align-items: stretch;
    background: #fff;
    display: flex;
    margin-bottom: $spacing;
    overflow: hidden;
    vertical-align: top;
    width: 100%;

    &--2-col {
        @include mq(small-up) {
            width: calc(50% - #{$smallSpacing / 2});
        }

        &:nth-child(odd) {
            @include mq(small-up) {
                margin-right: $smallSpacing;
            }
        }
    }

    &--3-col {
        @include mq(xsmall-up) {
            margin: 0 $smallSpacing $spacing 0;
        }

        @include mq(xsmall-and-small) {
            width: calc(50% - 15px);

            &:nth-child(even) {
                margin-right: 0;
            }
        }

        @include mq(medium-up) {
            width: calc(33.33% - 20px);

            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }

    &:hover {
        img {
            transform: scale(1.04);
        }
        #offices-item__inner-img::before {
            bottom: -5px;
        }
    }

    a {
        color: #000;
        display: block;
        text-decoration: none;
        hyphens: none;
        margin-bottom: 2rem;

        &:last-of-type {
            margin-bottom: 0;
        }

        &:hover {
            text-decoration: none;

            .arrow-icon--circle-2 {
                stroke-dashoffset: 0;
            }

            .arrow-icon--circle-1 {
                stroke-dasharray: 110;
                stroke-dashoffset: 110;
            }
        }
    }
    #offices-item__inner-img {
        position: relative;

        &:before {
            content: '';
            width: 100%;
            height: 6px;
            background: linear-gradient(to right, $orange, $pink);
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            transition: bottom 0.3s ease;
        }
    }
    h2 {
        margin-top: 0;
    }

    &__inner {
        position: relative;

        &__link {
            display: block;

            &[href] {
                cursor: pointer;
            }

            img {
                width: 100%;
                height: auto;
                transition: transform 0.3s ease;
            }
        }

        &__type {
            @include small-heading();
        }

        &__title {
            @include text-gradient();
            text-align: center;
            width: 100%;
            margin: 0 0 0.5rem;
            font-size: 22px;
            line-height: 1.2em;
            word-spacing: -1px;

            @include mq(xsmall-up) {
                font-size: 26px;
            }
        }

        &__text-container {
            background: white;
            padding: 2rem $smallSpacing;
            position: relative;
            width: calc(100% - $spacing);
            max-width: 480px;
            margin: auto 0;

            // @include mq(medium-up) {
            //   height: 100%;
            // }

            & > a {
                border-top: 1px solid #ccc;
                padding-top: 1.5rem;
            }

            @include arrow-styles();
        }

        &__text {
            font-size: 22px;
            line-height: 1.2em;
            word-spacing: -1px;
            text-align: center;
            margin: 1rem 0 0;
            hyphens: none;

            &--min-height {
                min-height: 2.385em;
            }

            @include mq(xsmall-up) {
                font-size: 26px;
            }
        }
    }
}
