.interactive-map {
  display: block;
  width: 100%;

  .map-container {
    position: relative;

    & > img {
      border: 1px solid #e3e4e5;
    }
  }

  img {
    opacity: 1;
    transition: opacity 0.5s;
    width: 100%;

    &.hidden {
      opacity: 0;
    }
  }

  .base-map-overlay {
    position: absolute;
    top: 0;
    z-index: 2;

    left: #{$smallSpacing / 2};
    max-width: 1320px;
    margin: 0 auto;
    width: calc(100% - #{$smallSpacing / 2} + 2px);

    @include mq(small-down) {
      left: #{$smallSpacing / 2};
      width: calc(100% - #{$smallSpacing});
    }

    @include mq(small-up) {
      left: #{$spacing};
      width: calc(100% - #{$spacing * 2});
    }
  }

  ul {
    height: calc(100% - 2px);
    left: 0;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;

    li {
      border: 1px solid #e3e4e5;
      display: block;
      height: 100%;
      opacity: 0;
      position: absolute;
      transition: opacity 0.5s;
      top: 0;
      width: 100%;
      z-index: 1;

      &.active {
        opacity: 1;
        z-index: 3;
      }
    }
  }

  a {
    display: block;
  }

  .links,
  .layers {
    left: #{$smallSpacing / 2};
    max-width: 1320px;
    margin: 0 auto;
    width: calc(100% - #{$smallSpacing / 2} + 2px);

    @include mq(small-down) {
      left: #{$smallSpacing / 2};
      width: calc(100% - #{$smallSpacing});
    }

    @include mq(small-up) {
      left: #{$spacing};
      width: calc(100% - #{$spacing * 2});
    }
  }

  .links {
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 5;

    a {
      cursor: pointer;
      display: block;
      left: 0;
      position: absolute;
      top: 0;
    }

    // a:nth-child(4) {
    //     left: 47%;
    //     top: 21%;
    //     width: 29%;
    // }

    a:nth-child(5) {
      left: 52%;
      top: 16%;
      width: 29%;
      height: 45%;
      svg {
        height: 100%;
        width: 100%;
      }
    }

    a:nth-child(3) {
      left: 20%;
      top: 65%;
      width: 8%;
      height: 18%;
    }

    a:nth-child(4) {
      left: 26%;
      top: 18%;
      width: 19%;
      height: 46%;
    }

    a:nth-child(2) {
      left: 51%;
      top: 62%;
      width: 24%;
    }

    a:nth-child(1) {
      left: 28%;
      top: 65%;
      width: 21%;
    }

    // a:nth-child(3) {
    //     left: 10%;
    //     top: 78%;
    //     width: 10%;
    // }
  }
}
