.story-item {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  margin-bottom: $spacing;

  @include mq(small-up) {
    width: calc(50% - #{$smallSpacing / 2});
  }

  &:nth-child(odd) {
    @include mq(small-up) {
      margin-right: $smallSpacing;
    }
  }

  &__inner {
    position: relative;

    img {
      width: 100%;
      height: auto;
    }

    &__title {
      @include text-gradient();
      text-align: center;
      width: 100%;
      margin: 0 0 .5rem;
      font-size: 22px;
      line-height: 1.2em;
      word-spacing: -1px;

      @include mq(xsmall-up) {
        font-size: 26px;
      }
    }

    &__text-container {
      background: white;
      padding: 2rem $smallSpacing;
      position: relative;
      width: calc(100% - $spacing);
      max-width: 480px;
      margin: -2rem auto 0;

      &:before {
        content: "";
        width: 100%;
        height: 6px;
        background: linear-gradient(to right, $orange, $pink);
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &__text {
      font-size: 22px;
      line-height: 1.2em;
      word-spacing: -1px;
      text-align: center;
      margin: 1rem 0 0;
      hyphens: none;

      @include mq(xsmall-up) {
        font-size: 26px;
      }
    }
  }
}