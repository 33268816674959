.occupier-logos {
  margin: $spacing 0;
  text-align: center;

  @include mq(large-up) {
    margin: $largeSpacing 0;
  }

  &__list {
    padding: 0;
    list-style: none;
    font-size: 0;
    text-align: left;
    margin-bottom: $smallSpacing;

    &__item {
      display: inline-block;
      width: 100%;
      vertical-align: top;
      text-align: center;
      margin: $smallSpacing 0;
      background-color: #fff;

      @media (max-width: 400px) {
        margin: 8px 0;
      }

      &:hover {
        cursor: pointer;

        img {
          transform: scale(1.1);
        }

        .plus-icon--circle-2 {
          stroke-dashoffset: 0;
        }

        .plus-icon--circle-1 {
          stroke-dasharray: 110;
          stroke-dashoffset: 110;
        }
      }

      &:first-child {
        @include mq(small-down) {
          margin-top: 0;
        }
      }

      @include mq(xsmall-and-small) {
        width: calc(50% - #{$smallSpacing});
        margin: $smallSpacing $smallSpacing $smallSpacing 0;

        &:nth-child(-n + 2) {
          margin-top: 0;
        }

        &:nth-child(even) {
          margin-right: 0;
        }
      }

      @include mq(medium-up) {
        width: calc(25% - #{$smallSpacing});
        margin: $smallSpacing $smallSpacing $smallSpacing 0;

        &:nth-child(-n + 4) {
          margin-top: 0;
        }

        &:nth-child(4n) {
          margin-right: 0;
        }
      }

      &__inner {
        width: 100%;
        position: relative;
        padding-bottom: 100%;
        overflow: hidden;
        font-size: 18px;

        @media (max-width: 400px) {
          padding-bottom: 63%;
        }

        @include mq(small-only) {
          font-size: 24px;
        }

        @include mq(large-up) {
          font-size: 20px;
        }
      }

      &__logo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 82%;
        text-align: center;
        z-index: 1;

        @media (max-width: 400px) {
          height: 72%;
        }

        &:before {
          content: "";
          display: inline-block;
          width: 0;
          height: 100%;
          vertical-align: middle;
        }

        img {
          display: inline-block;
          vertical-align: middle;
          max-width: 180px;
          max-height: 130px;
          transition: transform 0.3s ease;
          transform: scale(1);
          will-change: transform;
        }
      }

      &__summary {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        z-index: 2;
        transform: translateY(70%);
        transition: transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        // border-top: 1px solid $lightGrey;

        @include mq(small-up) {
          transform: translateY(76%);
        }

        &.is-open {
          transform: translateY(0%);

          .plus-icon {
            transform: rotate(45deg);
          }
        }

        &__inner {
          position: relative;
          width: 100%;
          height: 100%;

          &:before {
            content: "";
            display: inline-block;
            vertical-align: middle;
            width: 0;
            height: 100%;
          }
        }

        &__toggle {
          position: absolute;
          top: 1rem;
          left: 0;
          right: 0;
          margin: auto;
          display: block;
          width: 100%;
          text-align: center;

          .plus-icon {
            position: relative;
            top: -1px;
            -webkit-transition: -webkit-transform 0.3s ease;
            transition: -webkit-transform 0.3s ease;
            transition: transform 0.3s ease;
            transition: transform 0.3s ease, -webkit-transform 0.3s ease;
            vertical-align: middle;
            display: inline-block;
            width: 32px;
            height: 32px;

            @include mq(xsmall-up) {
              width: 38px;
              height: 38px;
            }
          }

          .plus-icon--circle-1 {
            transition: stroke-dashoffset 0.3s ease;
            stroke-dashoffset: 220;
          }

          .plus-icon--circle-2 {
            transition: stroke-dashoffset 0.3s ease 0.2s;
            stroke-dasharray: 110;
            stroke-dashoffset: 110;
          }
        }

        &__text {
          display: inline-block;
          vertical-align: middle;
          width: calc(100% - 40px);
          font-size: inherit;
          @include text-gradient();
        }

        &__link {
          position: absolute;
          bottom: 20px;
          left: 0;
          font-size: inherit;
          width: 100%;
          text-decoration: none;

          span {
            border-bottom: 2px solid $purple;
            // text-decoration: underline;
            color: $purple;
          }
        }
      }
    }
  }
}

.occupier-logos-new {
  margin: 0;
  text-align: center;

  @include mq(large-up) {
    margin: $largeSpacing 0;
  }

  &__list {
    padding: 0;
    list-style: none;
    font-size: 0;
    text-align: left;
    display: grid;
    margin-bottom: $smallSpacing;
    grid-template-columns: auto auto auto;

    &__item {
      display: inline-block;
      width: 100%;
      vertical-align: top;
      text-align: center;
      margin: $smallSpacing 0;
      background-color: #fff;

      @media (max-width: 400px) {
        margin: 8px 0;
      }

      &:hover {
        cursor: pointer;

        img {
          transform: scale(1.1);
        }

        .plus-icon--circle-2 {
          stroke-dashoffset: 0;
        }

        .plus-icon--circle-1 {
          stroke-dasharray: 110;
          stroke-dashoffset: 110;
        }
      }

      &:first-child {
        @include mq(small-down) {
          margin-top: 0;
        }
      }

      @include mq(xsmall-and-small) {
        width: calc(50% - #{$smallSpacing});
        margin: $smallSpacing $smallSpacing $smallSpacing 0;

        &:nth-child(-n + 2) {
          margin-top: 0;
        }

        &:nth-child(even) {
          margin-right: 0;
        }
      }

      @include mq(medium-up) {
        width: calc(25% - #{$smallSpacing});
        margin: $smallSpacing $smallSpacing $smallSpacing 0;

        &:nth-child(-n + 4) {
          margin-top: 0;
        }

        &:nth-child(4n) {
          margin-right: 0;
        }
      }

      &__inner {
        width: 100%;
        position: relative;
        padding-bottom: 100%;
        overflow: hidden;
        font-size: 18px;

        @media (max-width: 400px) {
          padding-bottom: 63%;
        }

        @include mq(small-only) {
          font-size: 24px;
        }

        @include mq(large-up) {
          font-size: 20px;
        }
      }

      &__logo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 82%;
        text-align: center;
        z-index: 1;

        @media (max-width: 400px) {
          height: 72%;
        }

        &:before {
          content: "";
          display: inline-block;
          width: 0;
          height: 100%;
          vertical-align: middle;
        }

        img {
          display: inline-block;
          vertical-align: middle;
          max-width: 180px;
          max-height: 130px;
          transition: transform 0.3s ease;
          transform: scale(1);
          will-change: transform;
        }
      }

      &__summary {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        z-index: 2;
        transform: translateY(70%);
        transition: transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        // border-top: 1px solid $lightGrey;

        @include mq(small-up) {
          transform: translateY(76%);
        }

        &.is-open {
          transform: translateY(0%);

          .plus-icon {
            transform: rotate(45deg);
          }
        }

        &__inner {
          position: relative;
          width: 100%;
          height: 100%;

          &:before {
            content: "";
            display: inline-block;
            vertical-align: middle;
            width: 0;
            height: 100%;
          }
        }

        &__toggle {
          position: absolute;
          top: 1rem;
          left: 0;
          right: 0;
          margin: auto;
          display: block;
          width: 100%;
          text-align: center;

          .plus-icon {
            position: relative;
            top: -1px;
            -webkit-transition: -webkit-transform 0.3s ease;
            transition: -webkit-transform 0.3s ease;
            transition: transform 0.3s ease;
            transition: transform 0.3s ease, -webkit-transform 0.3s ease;
            vertical-align: middle;
            display: inline-block;
            width: 32px;
            height: 32px;

            @include mq(xsmall-up) {
              width: 38px;
              height: 38px;
            }
          }

          .plus-icon--circle-1 {
            transition: stroke-dashoffset 0.3s ease;
            stroke-dashoffset: 220;
          }

          .plus-icon--circle-2 {
            transition: stroke-dashoffset 0.3s ease 0.2s;
            stroke-dasharray: 110;
            stroke-dashoffset: 110;
          }
        }

        &__text {
          display: inline-block;
          vertical-align: middle;
          width: calc(100% - 40px);
          font-size: inherit;
          @include text-gradient();
        }

        &__link {
          position: absolute;
          bottom: 20px;
          left: 0;
          font-size: inherit;
          width: 100%;
          text-decoration: none;

          span {
            border-bottom: 2px solid $purple;
            // text-decoration: underline;
            color: $purple;
          }
        }
      }
    }
  }
}
