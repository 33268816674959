@mixin text-gradient() {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0iZ3JhZGllbnQiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMCUiIHkyPSIxMDAlIj48c3RvcCBvZmZzZXQ9IjAlIiBzdHlsZT0ic3RvcC1jb2xvcjojRkY1MTAwOyIgLz48c3RvcCBvZmZzZXQ9Ijc0JSIgc3R5bGU9InN0b3AtY29sb3I6I0RFMkE5OTsiIC8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgZmlsbD0idXJsKCNncmFkaWVudCkiIGhlaWdodD0iMTAwJSIgd2lkdGg9IjEwMCUiIC8+PC9zdmc+);
    background-image: -webkit-gradient(
        linear,
        left top,
        -45deg,
        color-stop(0%, #ff5100),
        color-stop(74%, #de2a99)
    );
    background-image: -moz-linear-gradient(-45deg, #ff5100 0%, #de2a99 74%);
    background-image: -webkit-linear-gradient(-45deg, #ff5100 0%, #de2a99 74%);
    background-image: -o-linear-gradient(-45deg, #ff5100 0%, #de2a99 74%);
    background-image: -ms-linear-gradient(-45deg, #ff5100 0%, #de2a99 74%);
    background-image: linear-gradient(-45deg, #ff5100 0%, #de2a99 74%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    display: inline-block; /*required*/

    /* stylelint-disable */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        background: transparent;
        color: $pink;
    }
    /* stylelint-enable */
}

@mixin small-heading() {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 2px;
    text-align: center;
    display: block;
    margin: 0;

    @include mq(xsmall-up) {
        font-size: 16px;
        letter-spacing: 4px;
    }

    @include mq(medium-up) {
        font-size: 18px;
        letter-spacing: 4px;
    }
}

@mixin navigation-list() {
    margin: 0 auto;

    @include mq(medium-up) {
        max-width: 980px;
        overflow: hidden;
    }

    @include mq(large-up) {
        max-width: 1100px;
    }

    @include mq(xlarge-up) {
        max-width: 1200px;
    }
}

@mixin navigation-list-item() {
    align-items: center;
    background-color: $headerBackgroundColor;
    display: flex;
    float: left;
    justify-content: center;
    margin-right: 2rem;
    background-color: $headerBackgroundColor;
    text-align: center;
    max-width: 5.5rem;

    @include mq(medium-down) {
        display: block;
        max-width: 100%;
        // padding: 20px;
        text-align: left;
        width: calc(100% - 40px);
    }

    @include mq(medium-up) {
        // padding: 2.8rem 0;
        height: calc(5.6rem + 12px);
        max-width: 4.5rem;
    }

    @include mq(large-up) {
        // padding: 2.7rem 0;
        height: calc(5.4rem + 12px);
    }

    @include mq(xlarge-up) {
        // padding: 2.65rem 0;
        height: calc(5.3rem + 12px);
    }

    @include mq(medium-down) {
        float: none;
        width: 100%;
        border-bottom: 1px solid $lightGrey;
    }

    &:last-of-type {
        margin-right: 0rem;
    }

    &.is-active {
        & > a {
            position: relative;
            overflow: visible;
            @include text-gradient();
        }

        .sub-navigation {
            @include mq(medium-down) {
                display: block;
            }
        }
    }

    &.exit {
        &:before {
            content: '';
            position: absolute;
            line-height: 64px;
            background: url('../img/sub-navigation-icon.svg') no-repeat center
                center;
            background-size: 64px 64px;
            left: 0px;
            width: 64px;
            height: 64px;
            transform: rotate(180deg) translateX(5px);
        }
    }

    a {
        // padding: 2.65rem 0;
        color: inherit;
        text-decoration: none;
        display: block;

        @include mq(medium-down) {
            padding: 20px;
            width: calc(100% - 40px);
        }

        @include mq(medium-up) {
            // padding: 2.8rem 0;
        }

        @include mq(large-up) {
            // padding: 2.7rem 0;
        }

        @include mq(xlarge-up) {
            // padding: 2.65rem 0;
        }

        &:hover {
            cursor: pointer;
            @include text-gradient();
        }
    }
}

@mixin mq($point) {
    @if $point == xsmall-down {
        @media (max-width: 549px) {
            @content;
        }
    } @else if $point == xsmall-up {
        @media (min-width: 550px) {
            @content;
        }
    } @else if $point == xsmall-and-small {
        @media (min-width: 550px) and (max-width: 1080px) {
            @content;
        }
    } @else if $point == xsmall-only {
        @media (min-width: 550px) and (max-width: 767px) {
            @content;
        }
    } @else if $point == small-down {
        @media (max-width: 767px) {
            @content;
        }
    } @else if $point == small-up {
        @media (min-width: 768px) {
            @content;
        }
    } @else if $point == small-only {
        @media (min-width: 768px) and (max-width: 1080px) {
            @content;
        }
    } @else if $point == medium-down {
        @media (max-width: 1080px) {
            @content;
        }
    } @else if $point == medium-up {
        @media (min-width: 1080px) {
            @content;
        }
    } @else if $point == medium-and-large {
        @media (min-width: 1080px) and (max-width: 1299px) {
            @content;
        }
    } @else if $point == large-down {
        @media (max-width: 1190px) {
            @content;
        }
    } @else if $point == large-up {
        @media (min-width: 1190px) {
            @content;
        }
    } @else if $point == xlarge-up {
        @media (min-width: 1300px) {
            @content;
        }
    }
}

@mixin arrow-styles() {
    .arrow-icon {
        position: relative;
        top: -1px;
        -webkit-transition: -webkit-transform 0.3s ease;
        transition: -webkit-transform 0.3s ease;
        transition: transform 0.3s ease;
        transition: transform 0.3s ease, -webkit-transform 0.3s ease;
        vertical-align: middle;
        margin: 1.5rem auto 0;
        display: block;
        width: 32px;
        height: 32px;

        @include mq(xsmall-up) {
            width: 38px;
            height: 38px;
        }
    }

    .arrow-icon--circle-1 {
        transition: stroke-dashoffset 0.3s ease;
        stroke-dashoffset: 220;
    }

    .arrow-icon--circle-2 {
        transition: stroke-dashoffset 0.3s ease 0.2s;
        stroke-dasharray: 110;
        stroke-dashoffset: 110;
    }
}
