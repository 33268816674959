.retailer-item {
  float: left;
  margin-bottom: $smallSpacing;
  width: 100%;
  text-align: center;
  background-color: #FFF;

  &:hover {
    .arrow-icon--circle-2 {
      stroke-dashoffset: 0;
    }

    .arrow-icon--circle-1 {
      stroke-dasharray: 110;
      stroke-dashoffset: 110;
    }

    img {
      transform: scale(1.2);
    }
  }

  @include mq(xsmall-up) {
    margin: 0 $smallSpacing $spacing 0;
  }

  @include mq(xsmall-and-small) {
    width: calc(50% - 15px);

    &:nth-child(even) {
      margin-right: 0;
    }
  }

  @include mq(medium-and-large) {
    width: calc(33.33% - 20px);

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @include mq(xlarge-up) {
    width: calc(25% - 22.5px);

    &:nth-child(4n) {
      margin-right: 0;
    }
  }

  &__link {
    display: block;
    overflow: hidden;

    img {
      width: 100%;
      transform: scale(1);
      transition: transform .3s ease;
    }
  }

  &__text-container {
    position: relative;
    padding: 1rem;
    // height: 300px;
    padding-bottom: 87px;

    @include mq(small-up) {
      padding: 1rem;
      height: 300px;
    }
  }

  &__title {
    font-size: 20px;
    line-height: 1em;
    @include text-gradient();
    line-height: 1.2em;
    word-spacing: -2px;
    margin: 0;

    @include mq(small-up) {
      font-size: 32px;
    }
  }

  &__summary {
    font-size: 18px;
    word-spacing: -1px;
    line-height: 1.2em;
    text-overflow: 100px;
    overflow: hidden;

    @include mq(small-up) {
      font-size: 24px;
    }

    a {
      text-decoration: none;

      // &:hover {
      //   text-decoration: underline;
      // }
    }
  }

  &__cta {
    display: block;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    width: 100%;
    border-top: 1px solid #eee;
    background-color: #FFF;

    @include arrow-styles();
  }
}
