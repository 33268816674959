.heading {
    margin: $spacing auto;
    text-align: center;

    @include mq(medium-up) {
        margin: $largeSpacing auto;
    }

    &--no-bottom-margin {
        margin-bottom: 0;

        @include mq(medium-up) {
            margin-bottom: 0;
        }
    }

    &__date {
        @include small-heading();
        @include text-gradient();
        display: block;
        margin: 0 auto 2rem;
    }

    &__share {
        list-style: none;
        padding: 0;
        margin: 3rem 0;

        li {
            margin: 0 5px;
            display: inline-block;
            vertical-align: middle;
            max-width: 30px;
            max-height: 30px;

            svg {
                width: 100%;
            }
        }
    }

    &--black {
        h2 {
            background-image: none !important;
            color: #000;
            -webkit-text-fill-color: #000 !important;
        }
    }

    h2 {
        display: inline-block;
        font-size: 32px;
        font-weight: normal;
        margin: 0;

        &:not(.no-gradient) {
            @include text-gradient();
        }

        &.no-gradient {
            color: #fff;
        }

        @include mq(medium-up) {
            font-size: 62px;
            max-width: 70%;
        }
    }

    p {
        font-size: 22px;
        line-height: 1.2em;
        text-align: center;
        margin: 0 auto 2rem;
        max-width: 860px;
        margin-top: 2em;

        &.no-gradient {
            color: #fff;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        @include mq(xsmall-up) {
            font-size: 26px;
        }
    }

    h1,
    h3,
    h4,
    h5,
    h6 {
        @include text-gradient();
        font-size: 22px;
        line-height: 1.2em;
        text-align: center;
        margin: 0;

        @include mq(xsmall-up) {
            font-size: 26px;
        }
    }
}
