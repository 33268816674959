body {
  margin: 0;
  font-size: 20px;
  font-family: "Grotezk-Bold", helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: $siteBackground;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

.gradient-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom right, $pink, $orange);
  z-index: -1;
}

::selection {
  background-color: #000;
  color: #fff;
}

h1 {
  margin: 200px 0 0;
}

.hide-on-desktop {
  @include mq(medium-up) {
    display: none !important;
  }
}

.disable-link-on-mobile {
  @include mq(medium-down) {
    & > a {
      pointer-events: none;
    }
  }
}

a {
  color: inherit;
}

main#pjax-container {
  //padding-top: 110px;
  opacity: 0;
  transition: opacity 0.3s ease;

  &.is-visible {
    opacity: 1;
    padding-top: 110px;
  }
}

section {
  & > .wrapper {
    max-width: 1320px;
    padding: 0 #{$smallSpacing / 2};
    margin: 0 auto;

    @include mq(small-up) {
      padding: 0 $smallSpacing;
    }

    @include mq(small-up) {
      padding: 0 $spacing;
    }
  }
}

footer {
  background-color: $grey;

  & > .wrapper {
    max-width: 1320px;
    margin: 0 auto;
    padding: $smallSpacing;

    @include mq(small-up) {
      padding: $spacing;
    }
  }
}

video {
  opacity: 0;
  transition: opacity 1s ease;

  &.can-play {
    opacity: 1;
  }
}

.button {
  border: 0;
  -webkit-appearance: none;
  border: 3px solid $purple;
  background: none;
  color: $purple;
  display: inline-block;
  text-decoration: none;
  outline: 0;
  transition: opacity 0.3s ease;
  font-family: inherit;
  margin: $smallSpacing 0 0;

  &:hover {
    background-color: $purple;
    color: #fff;
    cursor: pointer;
  }

  &--white {
    border: 3px solid #fff;
    color: #fff;

    &:hover {
      background-color: #fff;
      color: $purple;
      cursor: pointer;
    }
  }

  &--pink {
    border: 3px solid $pink;
    color: $pink;

    &:hover {
      background-color: $pink;
      color: $white;
      cursor: pointer;
    }
  }

  &--large {
    padding: $smallSpacing / 2 $smallSpacing;
    font-size: 20px;
  }

  &.is-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.underline {
  text-decoration: underline;
}

.disabled {
  pointer-events: none;
}
